import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {FormikProps} from 'formik'
import { getStorageData } from "../../../framework/src/Utilities";
import { handleTriggerAlertMessage } from "../../../components/src/Navigator";
import moment from "moment";
const formInitValue = {price:null,category:'Category',cardName: null,id : null};
const customCardFormValue={id:null,account_id:null,client_name:null,trips_remaining:null,price:null,aircraft_category:null,from_airport:null,to_airport:null,valid_from:null,expiry_date:null,}

export interface AircraftCategoryType{
    "label": string,
    "value": string
}

interface AircraftCategoryResType{
  categories : AircraftCategoryType[]
}

export interface Card25hrType{
  "id": string,
  "type": string,
  "attributes": {
    "id": number,
    "aircraft_category":string,
    "name": string,
    "about": string,
    "price": string
  }
}

export interface CustomSkyCard{
  id: string,
  attributes:CustomSkycardInfo
  price:number
}

export interface CustomSkycardInfo{
  id: string|null,
  account_id: number|null,
  client_name: string|null,
  price:number|null,
  trips_remaining: number|null,
  aircraft_category: string|null,
  from_airport: Airport|null,
  to_airport: Airport|null,
  expiry_date: string|null,
  valid_from: string|null,
}

interface Card25HrResType{
  data : Card25hrType[]
}

interface FormInitValueType{
  price:null |string,
  category:null | string,
  cardName:null | string,
  id : null | number
}

interface CustomCardInitValues{
  id:string|null,
  aircraft_category:string|null,
  from_airport:Airport|null,
  to_airport:Airport|null,
  expiry_date:Date|string|null,
}

interface AddEdit25HrResType{
  data : Card25hrType
  meta : {message : string}
}

interface AddEditCustomCardResType{
  
  message:string
  data:{
    data:CustomSkyCard
  }
  id:string,
  attributes:CustomSkycardInfo,
  price:number
}

type CardType = null | 'debit' | '25hour'| "custom";

interface DebitSkyCardResType{
	"data": {
		"id":string,
		"type": string,
		"attributes": {
			"id": number,
			"debit_sky_card_id": null | string,
			"name": string,
			"current_top_ups": DebitFormInitValueType[],
			"remaining_balance": string
		}
	}
}

export interface DebitFormInitValueType{
  "id"?: number,
  "top_up": string
}

interface CustomSkyCardsResponse{
  total_pages:number,
  Custom_forms:{data:CustomSkyCard[]}
}

export interface Airports{
    id: string,
    name: string,
    icao: string,
    city: string,
    countryCode:string,
    code:string,        
}
export interface Airport {
  id: string
  
  name: string
  city: string
  country: {
    code: string
    name: string
  }
  icao: string
  iata: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activeField:string,
  add25CardInitValue:FormInitValueType,
  isViewStateOpen:boolean,
  isEditStateOpen:boolean
  isAddStateOpen:boolean
  viewCardId:string | null
  isDeleteModalOpen:boolean,
  aircraftCategory: [] | AircraftCategoryType[],
  card25HrData : [] | Card25hrType[],
  cardType : CardType,
  debitSkyCardInitValue : {current_top_ups : DebitFormInitValueType[]},
  addCustomCardInitValue:null |CustomSkycardInfo,
  customCardData:CustomSkyCard[],
  currentPage:number,
  totalPages:number|null,
  fromAirports:Airport[]
  toAirports:Airport[],
  customCardFormValues:CustomCardInitValues
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cf25hourskycardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  cardFormik: null | FormikProps<unknown> = null;
  getAircarftCategroyApiId:string=''
  get25HrSkyCardsApiId:string=''
  addEditDeleteSkyCardApiId:string=''
  getDebitSkyCardApiId:string=''
  addEditDebitCardTopApiId:string =''
  getCustomSkycardId:string=""
  isFromAirportTouched:boolean=true
  airportsTimer:NodeJS.Timeout|null = null
  getAirportsAPICallId:string = ""
  addEditDeleteCustomSkyCardApiId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      activeField:'',
      add25CardInitValue:formInitValue,
      isViewStateOpen:false,
      isEditStateOpen:false,
      isAddStateOpen:false,
      viewCardId:null,
      isDeleteModalOpen:false,
      aircraftCategory:[],
      card25HrData: [],
      cardType:null,
      debitSkyCardInitValue :{current_top_ups : [{top_up:'0'}]},
      addCustomCardInitValue:null,
      customCardData:[],
      currentPage:1,
      totalPages:null,
      fromAirports:[],
      toAirports:[],
      customCardFormValues:customCardFormValue
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(responseJson && !responseJson.errors){
        switch(apiRequestCallId){
          case this.getAircarftCategroyApiId:
          this.handleAircarftCatResponse(responseJson);  
          break;

          case this.get25HrSkyCardsApiId:
          this.handle25HrSkyCardResponse(responseJson);
          break;

          case this.getDebitSkyCardApiId:
          this.handleDebitCardResponse(responseJson);
          break;

          case this.addEditDeleteSkyCardApiId:
          this.handleAddEdit25HrResponse(responseJson);
          break;

          case this.addEditDebitCardTopApiId:
          this.handleAddEditDebitTopUp(responseJson);
          break;

          case this.getCustomSkycardId:
          this.handleCustomCardResponse(responseJson)
          break;

          case this.getAirportsAPICallId:
          this.handleAirportsResponse(responseJson.airports)
          break;

          case this.addEditDeleteCustomSkyCardApiId:
          this.handleAddEdit25HrResponse(responseJson)
          break;
            

        }
      }
    }
    
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.handleCardType();
    this.getAircraftCategory();

  }

  handleCardType=()=>{
    const {location:{pathname}} = window;
    let cardType:CardType = null;
    if(pathname === '/Manage25hourskycard'){
      cardType = '25hour';
      this.get25HrSkyCards();
    } 
    else if(pathname === '/Managedebitskycard3'){
      cardType = 'debit';
      this.getDebitSkyCard();
    }
    else{
      cardType = "custom"
      this.getCustomSkyCards()
    } 
    this.setState({cardType});
  }

  handleActiveField=(activeField:string)=>{
    this.setState({activeField});
  }

  handleError=(touched:boolean,error:boolean)=>{
    return touched && error
  }

  handleViewEditSkyCard=(isAdd=this.state.isAddStateOpen,isView=this.state.isViewStateOpen,isEdit=this.state.isEditStateOpen)=>{
    const {cardType} = this.state
    if(!isView && !isEdit){
      cardType === "custom" ? this.setState({addCustomCardInitValue:customCardFormValue}):this.setState({add25CardInitValue:formInitValue})
    };
    this.setState({isAddStateOpen:isAdd,isViewStateOpen:isView,isEditStateOpen:isEdit});
  }

  handleDeleteModal=(isOpen:boolean)=>{
    this.setState({isDeleteModalOpen:isOpen});
  }

  getAircraftCategory=async()=>{
    const headers = {
      token : await getStorageData('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
     this.getAircarftCategroyApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAircraftApiEndPoint
    );

     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAircarftCatResponse=(responseJson : AircraftCategoryResType)=>{
    if(responseJson.categories){
      this.setState({aircraftCategory:responseJson.categories});
    }
  }

  getDebitSkyCard=async()=>{
    const headers = {
      token : await getStorageData('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
     this.getDebitSkyCardApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDebitSkyCardEndPoint
    );
     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addEditDebitkyCardTopUp=async(formData:{current_top_ups: DebitFormInitValueType[]})=>{
    const handleData = this.handleDebitSkyCardData(formData);
    const headers = {
      'token' : await getStorageData('token'),
      'Content-type' : configJSON.exampleApiContentType
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addEditDebitCardTopApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addDebitTopUpEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(handleData)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

    handleDebitSkyCardData=(formData : {current_top_ups: DebitFormInitValueType[]})=>{
      const {current_top_ups} = formData;
      let top_ups = current_top_ups.map((each_top)=> this.manageEachTop(each_top))
      let dataToSend = {
        data : {
          debit_top_ups_attributes : top_ups
        }
      }
      return dataToSend
    }

    manageEachTop=(each_top_up:DebitFormInitValueType)=>{
      const {top_up,id} = each_top_up;
      const top_up_id = id ? {id} : {};
      return {amount : top_up,...top_up_id};
    }

    handleAddEditDebitTopUp=(responseJson:DebitSkyCardResType)=>{
      if(responseJson.data){
        const {data:{attributes:{current_top_ups}}} = responseJson;
        this.setState({debitSkyCardInitValue:{current_top_ups}});
        this.handleViewEditSkyCard(false,true,false);
        handleTriggerAlertMessage(this.send);
      }
    }

  get25HrSkyCards=async()=>{
    const headers = {
      token : await getStorageData('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
     this.get25HrSkyCardsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.get25HrSkyCardEndPoint
    );

     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handle25HrSkyCardResponse=(responseJson:Card25HrResType)=>{
      if(responseJson.data){
        this.setState({card25HrData:responseJson.data});
      }
  }

  handleDebitCardResponse=(responseJson : DebitSkyCardResType)=>{
    if(responseJson.data){
      const {data:{attributes : {current_top_ups}}} = responseJson;
      this.setState({debitSkyCardInitValue : {current_top_ups}})
    }
  }


  addEditDelete25SkyCard=async(formData:FormInitValueType)=>{
    const {isAddStateOpen,isDeleteModalOpen} = this.state;
    const {id} = formData;
    const handleData = this.handleFormData(formData);
    const endPoint =  configJSON.get25HrSkyCardEndPoint;
    const cardId =  isAddStateOpen ? '' : `/${id}`;
    const addEditMethod = isAddStateOpen ? configJSON.exampleAPiMethod : configJSON.updateCardMethod;
    const method = isDeleteModalOpen ? configJSON.deleteCardMethod : addEditMethod;
    const headers = {
      'token' : await getStorageData('token'),
      'Content-type' : configJSON.exampleApiContentType
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addEditDeleteSkyCardApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint + cardId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(handleData)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}


  handleFormData=(values:FormInitValueType)=>{
    const {cardName,category,price} = values;
    return {
      "data":{
          aircraft_category: category,
          price,
          name:cardName
      }
  }
  }

  handleAddEdit25HrResponse=(response:AddEdit25HrResType|AddEditCustomCardResType)=>{
    
    const {cardType,isEditStateOpen} = this.state;
    const isCustomSkycard = cardType === "custom"
    const message = isCustomSkycard ? (response as AddEditCustomCardResType).message:(response as AddEdit25HrResType).meta.message
    const responseJson = isCustomSkycard ? (response as AddEditCustomCardResType ).data.data :(response as AddEdit25HrResType).data
    if(message){
      isCustomSkycard?this.getCustomSkyCards():this.get25HrSkyCards();
      {isEditStateOpen && (isCustomSkycard ? this.handleViewCustomSkycard(responseJson as AddEditCustomCardResType):this.handleViewCard(responseJson as Card25hrType))}
      handleTriggerAlertMessage(this.send,message);
      this.handleViewEditSkyCard(false,isEditStateOpen ? true : false,false);
      this.setState({isDeleteModalOpen:false});
    }
  }

  handleViewCard=(value:Card25hrType)=>{
    const {attributes:{aircraft_category,name,price,id}} = value;
    this.setState({add25CardInitValue:{
      cardName:name,
      category:aircraft_category,
      price,
      id
    }});
  }

   getHeading = ()=>{
    const {cardType} = this.state
    if(cardType === "debit"){
      return "Debit"
    }
    else if(cardType === "25hour"){
      return "25 Hour"
    }
    else {
      return "Custom"
    }
  }

  getCustomSkyCards=async()=>{
    const currentPage = 1
    const itemsPerPage = 4


    const headers = {
      token : await getStorageData('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
     this.getCustomSkycardId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSkyCardsAPIEndpoint}?page_number=${currentPage}&number_of_items_per_page=${itemsPerPage}`
    );
     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCustomCardResponse = (responseJson:CustomSkyCardsResponse)=>{
    const {total_pages,Custom_forms:{data}} = responseJson
    this.setState({customCardData:data,totalPages:total_pages})
  }

  handleViewCustomSkycard = (values:CustomSkyCard)=>{
    const {id,attributes:{aircraft_category,expiry_date,from_airport,to_airport,}} = values
    this.setState({addCustomCardInitValue:{...values.attributes,id,price:23},customCardFormValues:{from_airport,to_airport,expiry_date,aircraft_category,id}})
  }

  labelProvider=(value:Airport)=>{
    if(value){
       return `${value.icao} - ${value.city}, ${value.country.code}`;
    }
  }

  handleAirportSearch = (valueToSearch:string,isFromAiport:boolean) => {
    this.isFromAirportTouched = isFromAiport
    if (this.airportsTimer) clearTimeout(this.airportsTimer);

    if(valueToSearch.length > 2){
      this.airportsTimer = setTimeout(() => {
        this.getAirports(valueToSearch);
      }, 1000);
    }
  };

  getAirports = (value:string)=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAirportsAPICallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.airportsSearchAIPIEndpoint}=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleAirportsResponse = (airports:Airport[])=>{
  
    if(this.isFromAirportTouched){
      this.setState({fromAirports:[...airports]})
    }
    else {
      this.setState({toAirports:[...airports]})
    }
    
  }

  formatDate = (date:Date|string) => {
    if(date){
      return typeof date === "string"?moment(date,"DD/MM/YYYY").format("DD MMMM YYYY"): moment(date).format('D MMMM YYYY')
    }
    else{
      return ""
      
    }
  };

  addEditDeleteCustomSkyCard=async(formData:CustomCardInitValues)=>{
    const {isAddStateOpen,isDeleteModalOpen} = this.state;
    const {id} = formData;
    const handleData = this.handleCustomSkycardFormData(formData);
    const endPoint =  configJSON.getSkyCardsAPIEndpoint;
    const cardId =  isAddStateOpen ? '' : `/${id}`;
    const addEditMethod = isAddStateOpen ? configJSON.exampleAPiMethod : configJSON.updateCustomSkycardMethod;
    const method = isDeleteModalOpen ? configJSON.deleteCardMethod : addEditMethod;
    const headers = {
      'token' : await getStorageData('token'),
      'Content-type' : configJSON.exampleApiContentType
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addEditDeleteCustomSkyCardApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint + cardId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    method !== "DELETE" && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(handleData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

handleCustomSkycardFormData=(values:CustomCardInitValues)=>{
  const {from_airport,to_airport,expiry_date,aircraft_category} = values;
  const skyCardData = {
    form_attributes:{
      from_airport:from_airport?.id,
      to_airport:to_airport?.id,
      expiry_date:this.formatDate(expiry_date as Date),
      aircraft_type:aircraft_category
    }
  }
  
    return skyCardData
}

getRequiredValues = ()=>{
  const {activeField,isViewStateOpen, isEditStateOpen} = this.state;
  const disableBg ={backgroundColor:"#2D3034",borderRadius:"5px",color:"white"}
  const formLabel = !isEditStateOpen ? 'Add' : 'Edit'
  const values = {
    textFieldStyle : isViewStateOpen ? disableBg : {},
    textSelectStyle : isViewStateOpen ? {...disableBg,padding : '1rem'} : {background:'#1A1C1E'},
    isOutlined : !isViewStateOpen ? 'outlined' : 'standard',
    label :formLabel,
    formLabel : isViewStateOpen ? '' : formLabel,
    fromBorder : activeField==="from_airport"?"1px solid #FFD17A":"1px solid #636E72",
    toBorder : activeField==="to_airport"?"1px solid #FFD17A":"1px solid #636E72"
  }
  return values
}

getBackgroundColor = ()=>{
  return this.state.isViewStateOpen?"#2D3034": '#1A1C1E'
}

getErrors = (touched:undefined|boolean,errors:string|undefined)=>{

  return touched && errors

}

getActiveField = (fieldName:string)=>{
  const {activeField} = this.state

  return activeField === fieldName

}

getLabel = (formLabel:string|undefined)=>{
  return  formLabel === '' ? formLabel : 'Choose'
}


  

  

  // Customizable Area End
}
