Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cf25hourskycard";
exports.labelBodyText = "cf25hourskycard Body";

exports.btnExampleTitle = "CLICK ME";
exports.getAircraftApiEndPoint = 'bx_block_cfavinodeintegration2/aircrafts/aircraft_categories'
exports.get25HrSkyCardEndPoint = 'bx_block_cf25hourskycard/hours_sky_cards';
exports.updateCardMethod = 'PUT';
exports.deleteCardMethod = 'DELETE';
exports.getDebitSkyCardEndPoint = 'bx_block_cfdebitskycard3/debit_sky_cards/debit_card';
exports.addDebitTopUpEndPoint  = 'bx_block_cfdebitskycard3/debit_sky_cards/add_debit_top_ups';
exports.getSkyCardsAPIEndpoint = "bx_block_custom_forms/custom_forms"
exports.airportsSearchAIPIEndpoint = "bx_block_cfavinodeintegration2/airports?query"
exports.updateCustomSkycardMethod = "PATCH"

// Customizable Area End