import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {ProfileDataType, AirportType, Airports, BookingsData, PassengerData, PassengerFormValues, PassengerInfo,imgPasswordInVisible, imgPasswordVisible, formikCobmineTypeType, CardDetails, FileData, visa, americanExpress, defaultCard, masterCard, FileValues } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { ICountry, IState, ICity, Country, State,City} from 'country-state-city';
import { handleNavigation } from "../../../components/src/Navigator";
import React from "react";
import moment from "moment";
import { FormikProps, FormikValues } from "formik";
import { DateObject } from "react-native-calendars";
import { convertToMMDDYYYY } from "../../../components/src/ReUsableModal";


type DynamicStringObject = {
  [key: string]: string;
};



// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  loader: boolean;
  profileData: ProfileDataType;
  accountId: number | null;
  totalSpend: number | null;
  upComingBookingData: BookingsData[];
  previousBookingsData: BookingsData[];
  passengerDetails: PassengerData[];
  error: boolean;
  showEmailSentModal: boolean;
  countries:null | ICountry[],
  states:[] |IState[],
  cities:[] | ICity[],
  selectedCountry: null | ICountry,
  selectedState : null | IState,
  selectedCity:null | ICity,
  dietryData:string[],
  showEditProfile:boolean,
  totalBookings:number|null,
  airports:Airports[],
  searchedAirports:Airports[],
  profileId:string|null
  showPassengerModal:boolean,
  focusedField:string|null,
  action:string|null;
  passengerDataIndex:number,
  passengerId:string|null,
  showDeactiveAccountModal:boolean,
  status:string,
  showPaymentModal:boolean,
  cardNumber:number,
  cards:CardDetails[]
  updateEmailModal:boolean,
  modalData:string,
  isSureModalOpen:boolean,
  cardIdToDelete:null | number,
  isPassengerDeleting : boolean,
  profilePicture:File|null,
  editPictureModal:boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Adminconsole2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getClientDetailsAPICallId: string = "";
  clientId: string | null = "";
  resetPasswordAPICallId: string = "";
  formikForm: null| FormikProps<unknown> = null; 
  popularAirportsAPICallId:string=""; 
  editPersonalInfoAPICallId:string="";
  delayTimer : NodeJS.Timeout|string="";
  getAirportsAPICallId: string = "";
  inputFileRef = React.createRef<HTMLInputElement>();
  submitPassengerInfoAPICallId:string="";
  deletePassengerAPICallId :string = "";
  passportFieldRef = React.createRef<HTMLInputElement>();
  deactivateAccountAPICallId :string =  "";
  createNewCardAPICallId:string = "";
  makePrimaryMethodAPICallId:string = "";
  deleteCardAPICallId:string = "";
  updateCardDetailsAPICallId:string = "";
  cardFormik:FormikProps<FormikValues>|null = null
  emailFormik:FormikProps<unknown>|null = null;
  updateEmailAPICallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      loader: true,
      accountId: null,
      totalSpend: null,
      previousBookingsData: [],
      upComingBookingData: [],
      profileData: {
        full_name:"",
        email:null,
        phone_number:null,
        countryCode:"",
        preferred_departure_airport:"",
        street_address:null,
        zip_code:null,
        dietary_requirements: 'Please Specify',
        photo:null,
        account_id:null,
        city:"",
        state:null,
        main_country_of_residence:null,
        phoneNumber:"",
        companyName:"",
      },
      passengerDetails: [],
      error: false,
      showEmailSentModal:false,
      countries:Country.getAllCountries(),
      selectedCountry: null,
      selectedState : null,
      states: [],
      cities:[],
      selectedCity:null,
      dietryData:['Vegan','Vegetarian','Pescatarian', 'Gluten-free', 'Dairy-free', 'I eat anything', 'Other'],
      showEditProfile:false,
      totalBookings:null,
      airports:[],
      searchedAirports:[],
      profileId:null,
      showPassengerModal:false,
      focusedField:null,
      action:null,
      passengerDataIndex:-1,
      passengerId:"",
      showDeactiveAccountModal:false,
      status:"",
      showPaymentModal:false,
      cardNumber:-1,
      cards:[],
      updateEmailModal:false,
      modalData:"",
      isSureModalOpen:false,
      cardIdToDelete:null,
      isPassengerDeleting : false,
      profilePicture:null,
      editPictureModal:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && responseJson.data) {

      if (apiRequestCallId === this.getClientDetailsAPICallId) {
        const clientDetails = responseJson.data.data.attributes
        const {attributes:{id,email,full_name,company_name,dietary_requirements,street_address,zipcode,full_phone_number,preferred_departure_airport,photo,account_id,main_country_of_residence, state, city, country_code, phone_number,total_bookings}} = clientDetails.profile.data;
        
        this.setState({profileData:{email,full_name,street_address,dietary_requirements,zip_code:zipcode,
          city,state,phone_number:full_phone_number,companyName:company_name,preferred_departure_airport,photo,account_id,main_country_of_residence, 
          countryCode:country_code,phoneNumber:phone_number,},
          loader: false,previousBookingsData: clientDetails.bookings.previous,totalSpend:clientDetails.total_spend,totalBookings:total_bookings,
          passengerDetails: clientDetails.passenger_details.data, upComingBookingData: clientDetails.bookings.upcoming,
          accountId: clientDetails.account_number,profileId:id,status:clientDetails.status,cards:clientDetails.payment_method.cards.data},()=>{
        });
      }

      else if (apiRequestCallId === this.resetPasswordAPICallId) {
        this.setState({ showEmailSentModal: true,modalData:"Password" })
      }

      else if(apiRequestCallId === this.deactivateAccountAPICallId){
        this.setState({showDeactiveAccountModal:false},()=>{
          this.getClientDetails()
        })
      }

      else if(apiRequestCallId === this.popularAirportsAPICallId){
        this.handleAirportsResponse(responseJson.data,true)
      }

      else if(apiRequestCallId === this.getAirportsAPICallId){
        this.handleAirportsResponse(responseJson.airports,false)
      }
      else if(apiRequestCallId === this.editPersonalInfoAPICallId){
        this.setState({showEditProfile:false},()=>{
          this.handleTriggerAlertMessage("Changes saved successfully")
          this.getClientDetails()
        })
      }
      else if(apiRequestCallId === this.submitPassengerInfoAPICallId){
        this.setState({showPassengerModal:false,focusedField:null},()=>{
          const message = this.getMessageForPassengerData()
          this.handleTriggerAlertMessage(message)
          this.getClientDetails()
        })
       

      }
      else if(apiRequestCallId === this.deletePassengerAPICallId){
        this.setState({showPassengerModal:false},()=>{
          this.handleTriggerAlertMessage("Passenger deleted successfully")
          this.getClientDetails()
        })
          
      }

      this.handleAPIResponse(apiRequestCallId,responseJson)
    }

    else if (responseJson.errors) {
      this.handleErrorResponse(apiRequestCallId,responseJson)       
    }
    else if(responseJson.message ){
      this.handleMessage(apiRequestCallId,responseJson)
      
    }

    this.handleSureModalOpen(false,false);
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleSureModalOpen=(isOpen:boolean,isPassengerDeleting:boolean,cardIdToDelete=null)=>{
    this.setState({isSureModalOpen:isOpen,cardIdToDelete:cardIdToDelete,isPassengerDeleting});
  }

  async componentDidMount() {
    super.componentDidMount();
    const queryParams = new URLSearchParams(window.location.search)
    this.clientId = queryParams.get("client_id")
    this.getClientDetails()

  }
  getFileName = (imageURL: string | null) => {

    if (imageURL) {
      const parts = imageURL.split("/")
      let imageName = parts[parts.length - 1]
      const fileName = imageName.split(".")
      const fileType = fileName[fileName.length - 1]
      if (imageName.length > 12) {
        imageName = `${imageName.substring(0, 5)}***.${fileType}`
        return imageName
      }
      else {
        return imageName
      }
    }
    else {
      return "image"
    }

  }

  getFieldvalue = (value:string|null)=>{
    if(value){
      return value
    }
    else {
      return "----"
    }
  }

  getClientDetails = async () => {
    const header = {
      token: await getStorageData("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getClientDetailsAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getClientDetailsAPIEndpoint}/${this.clientId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResetPassword = async() => {
    const {account_id} = this.state.profileData;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token" : await getStorageData('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resetPasswordAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordApiEndpoint + `?id=${account_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

handleNavigations = (path:string,bookingId?:number)=>{
    
  if(path === "orderManagement"){
    handleNavigation(`OrderManagement?booking_id=${bookingId}`,this.props,this.send)
  }
  else if(path === "previousBookings"){
    handleNavigation(`PreviousBookings?client_id=${this.clientId}&client_name=${this.state.profileData.full_name}`,this.props,this.send)
  }
  else if(path === "upcomingBookings"){
    handleNavigation(`OrderDetailsView?client_id=${this.clientId}&client_name=${this.state.profileData.full_name}`,this.props,this.send)
  }
  else if(path === "Clients"){
    handleNavigation("Clients",this.props,this.send)
  }
}

getPopularAirportsApi = () => {
   
  const header = {
    "Content-Type": configJSON.airportsContentType,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.popularAirportsAPICallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.popularSeachApi
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

handleAirportsResponse = (airports:AirportType[],isPopular:boolean)=>{
  let airportManipulatedData = airports.map((item:AirportType)=>{
    return {name:item.name,city:item.city,countryCode:item.country.code,code:item.icao,id :item.id}
  })

  if(isPopular){
    this.setState({airports:airportManipulatedData,searchedAirports:airportManipulatedData})
  }
  else{
    this.setState({searchedAirports:airportManipulatedData})
  }
}

getOptionLabel = (option:{name:string|null,city:string|null,code:string|null})=>{

  if(option.city){
    return `${option.code} - ${option.name}, ${option.city}`
  }
  else{
    return option.name
  }

}

getUrl = (photoLink:string|Blob)=>{

  let imageURL=""
        if (photoLink instanceof Blob) {
        imageURL = URL.createObjectURL(photoLink);
    } else {
        imageURL = photoLink;
    }

    return imageURL

}

editPersonalInfo = async(values:formikCobmineTypeType)=>{
  const header = {
    token : await getStorageData('token')
  }

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.editPersonalInfoAPICallId = requestMessage.messageId;
  let formData = this.handleFormData(values)

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
   configJSON.editPersonalInfoAPIEndpoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.patchMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage)
}

handleFormData = (values:formikCobmineTypeType)=>{

  let formData = new FormData();
  formData.append("data[attributes][preferred_departure_airport]",values.preferredAirport.city ? `${values.preferredAirport.name}, ${values.preferredAirport.city} - ${values.preferredAirport.code}`:values.preferredAirport.name)
  formData.append("data[attributes][full_name]",values.full_name)
  values.dietary_requirements && formData.append("data[attributes][dietary_requirements]",values.dietary_requirements);
  values?.optedCountry?.name && formData.append("data[attributes][main_country_of_residence]",values.optedCountry.name);
  formData.append("data[attributes][state]",values?.state || "");
  formData.append("data[attributes][city]",values?.city || "");
  values?.zip_code !== null && formData.append("data[attributes][zipcode]",values.zip_code.trim());
  values.companyName !== null && formData.append("data[attributes][company_name]",values.companyName?.trim())
  values?.street_address !== null && formData.append("data[attributes][street_address]",values.street_address.trim());
  formData.append("data[attributes][country_code]",values.countryCode);
  formData.append("data[attributes][phone_number]",values.phoneNumber);
  values.photo&& typeof(values.photo) !== "string" && formData.append("data[attributes][photo]",values.photo)
  formData.append("data[attributes][id]",String(this.state.profileId))
  return formData;
}

handleFocusedField = (fieldName:string|null)=>{
  this.setState({focusedField:fieldName})
}

handleAirportSearch = (value: string) => {
  clearTimeout(this.delayTimer)
  this.delayTimer = setTimeout(() => {
      clearTimeout(this.delayTimer);
      this.handleAPICallForAirports(value)
  }, 500);
}

handleAPICallForAirports = (changedValue:string)=>{
  if(changedValue.length > 2){
    this.getAirports(changedValue)
  }else{
    this.setState({searchedAirports:this.state.airports});
  }
}

getAirports = (value:string) => {
   
  const header = {
    "Content-Type": configJSON.advancedsearchApiContentType,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getAirportsAPICallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.airportsAPIEdnpoint}=${value}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};




handlePassengerInfo =async (values:PassengerFormValues)=>{
  const {action,passengerId,profileData} = this.state
   
  let userData:PassengerInfo = {
    data:{
        first_name: values.firstName,
        last_name:values.lastName,
        passport_number:values.passportNumber,
        date_of_issuance:values.dateOfInsurance as unknown as string,
        date_of_expiration:values.dateOfExpiration as unknown as string,
        country_of_issue:values.countryOfIssue.name,
        country_code:values.countryCode,
        phone_number:values.phoneNumber,
        email:values.emailId,
        full_address:values.address,
        gender:values.gender,
        weight:values.weight,
        date_of_birth:values.dateOfBirth as unknown as string,
        company_name:values.companyName,
    },
    passport_images: true
  }

  if(action==="edit"){
    const uploadedFile:FileValues[] = [];

    (values.passportFile as []).forEach((file:FileValues)=>{
      if(!file.url){
        uploadedFile.push(file)
      }
    })
     if(uploadedFile.length > 0){
      userData.data.passport_file = await this.uploadPassport(uploadedFile as FileValues[])
     }
  }
  else{
    userData.data.passport_file = await this.uploadPassport(values.passportFile)
  }
  

  if(action === "add"){
    userData.client_id = profileData?.account_id
  }
  else userData.id = passengerId

  const apiUrl = action !== "add" ?configJSON.updatePassengerAPIEndpoint : configJSON.submitPassengerInfoAPIEndpoint
  const apiMethod = action !== "add" ? configJSON.updatePassengerMethod : configJSON.httpPostMethod
  const header = {
    token: await getStorageData("token"),
    "Content-Type": configJSON.validationApiContentType,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.submitPassengerInfoAPICallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    apiUrl
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(userData)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    apiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}

getInputStyle = ()=>{
  return(this.state.focusedField === "phoneNumber"?{border:"1px solid #FFD17A",height:"3.8rem"}:{ padding: "15px 14px 15px 70px",height:"3.8rem" })
}

handleErrorResponse = (apiRequestCallId:string,responseJson:{errors:DynamicStringObject[]})=>{

  if (apiRequestCallId === this.getClientDetailsAPICallId) {
    this.setState({ loader: false, error: true })
  }
  else if(apiRequestCallId === this.editPersonalInfoAPICallId){
    if(responseJson.errors[0].full_phone_number){
      this.formikForm?.setFieldError("phone_number",responseJson.errors[0].full_phone_number)
    }
  }
  else if(apiRequestCallId === this.updateEmailAPICallId){
    if(responseJson.errors[0].account === "Account already exists with the provided email"){
      (this.emailFormik as FormikProps<FormikValues>).setFieldError("email","Account already exists with the provided email")


    }
  }
  else if(apiRequestCallId === this.createNewCardAPICallId){
    
    if(responseJson.errors[0].card_number){
      
      (this.cardFormik as FormikProps<FormikValues>).setFieldError("cardNumber",responseJson.errors[0].card_number)
    }
    if(responseJson.errors[0].expiry_date || responseJson.errors[1]?.expiry_date){
      (this.cardFormik as FormikProps<FormikValues>).setFieldError("expiryDate","Expired Card, Please use valid card")

    }

  }

}

handleMessage = (apiRequestCallId:string,responseJson:{message:DynamicStringObject})=>{
   if(apiRequestCallId === this.deactivateAccountAPICallId){
    this.setState({showDeactiveAccountModal:false},()=>{
      this.getClientDetails()
    })
  }

  else if(apiRequestCallId === this.createNewCardAPICallId){
    if(responseJson.message.error_message==="Invalid card number"){
      (this.cardFormik as FormikProps<FormikValues>).setFieldError("cardNumber","Invalid card number")

    }
  }
  else if(apiRequestCallId === this.makePrimaryMethodAPICallId){
    this.handleTriggerAlertMessage("Card added as primary method")
    this.getClientDetails()        
  }
  else if(apiRequestCallId === this.deleteCardAPICallId){
    this.closePaymentModal()
    this.handleTriggerAlertMessage("Card deleted successfully")
    this.getClientDetails()
  }

  else if(apiRequestCallId === this.deletePassengerAPICallId){
    this.setState({showPassengerModal:false,action:null});
    this.getClientDetails();
    this.handleTriggerAlertMessage("Passenger deleted successfully");
  }

  else if(apiRequestCallId === this.updateEmailAPICallId){
    this.setState({showEmailSentModal:true,modalData:"Email",updateEmailModal:false,})
  }
}

openEditPersonalInfoModal = ()=>{
  this.setState({ showEditProfile: true },()=>this.getPopularAirportsApi())
}

closeEditPersonalInfoModal = ()=>{
  this.setState({showEditProfile:false,focusedField:null})
}

openPassengerModal = (action:string,passengerNumber:number=-1,passengerId:string="")=>{
  if(passengerNumber===-1){
    this.setState({action:action})
  }
  else{
    this.setState({showPassengerModal:true,action:action,passengerDataIndex:passengerNumber,passengerId:passengerId})
  }  
}
closePassengerModal = ()=>{
  this.setState({showPassengerModal:false,action:null,focusedField:null,passengerDataIndex:-1})
}

closeEmailSentModal = ()=>{
  this.setState({showEmailSentModal:false},()=>{
    if(this.state.modalData === "Email"){
      this.getClientDetails()
    }
  })
}

deletePassenger =async () => {
  const {cardIdToDelete} = this.state;
  const header = {
    token: await getStorageData("token")
  };

  const deletePassengerMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.deletePassengerAPICallId = deletePassengerMsg.messageId;

  deletePassengerMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.deletePassengerAPIEndpoint}=${cardIdToDelete}`
  );

  deletePassengerMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  deletePassengerMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.deletePassengerMethod
  );

  runEngine.sendMessage(deletePassengerMsg.id, deletePassengerMsg);
};

getPassengerData = ()=>{
  if(!this.state.action || this.state.action==="add"){return}
  const passengerData = this.state.passengerDetails[this.state.passengerDataIndex]
  const {first_name,id,last_name,passport_number,country_of_issue,email,weight,date_of_birth,date_of_expiration,date_of_issuance,gender,full_address,phone_number,country_code,passport_images} =passengerData.attributes
  const passengerDetails = {
    passengerId:id,
    firstName: first_name,
    lastName:last_name,
    dateOfBirth:convertToMMDDYYYY(date_of_birth),
    gender:gender,
    passportNumber:passport_number,    
    phoneNumber:phone_number,
    dateOfInsurance:convertToMMDDYYYY(date_of_issuance),
    countryOfIssue:{name:country_of_issue},
    emailId:email,
    address:full_address,
    weight:weight,
    dateOfExpiration:convertToMMDDYYYY(date_of_expiration),
    countryCode:country_code,
    fullNumber:`${country_code}${phone_number}`, 
    passportFile:passport_images?passport_images:[]  
  }
  return passengerDetails
}

handleTriggerAlertMessage=(toastMessage:string)=>{
  const messageTriger = new Message(getName(MessageEnum.AlertMessage));
  messageTriger.addData(getName(MessageEnum.AlertBodyMessage), toastMessage);
  this.send(messageTriger);
}

getMessageForPassengerData = ()=>{
  const message = this.state.action === "add" ? "Passenger added successfully" :"Changes saved successfully"
  return message

}

getAccountStatus = (status:string)=>{

  if(status === "deactivated"){
    return "Deactivated"
  }
  else{
    return "Active"
  }
}

handleDeactivateAccountModal = (actionType:string)=>{
  if(actionType === "open"){
    this.setState({showDeactiveAccountModal:true})
  }
  else{
    this.setState({showDeactiveAccountModal:false})
  }

}

handleDeactiveAccount = async()=>{  
    
     const accountData = {
        account_id: this.clientId,               
      }
        
    const header = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deactivateAccountAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deactivateAccountAPIEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(accountData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
     
}

openPaymentModal = (action:string,cardNumber=-1)=>{
  if(action === "edit" && cardNumber <0){
    cardNumber = this.state.cardNumber
  } 
  this.setState({showPaymentModal:true,action:action,cardNumber:cardNumber})
}

getCardDetails = ()=>{ 
  const {cards,action,cardNumber} = this.state
  if(!action || action==="add"){return}
  const card = cards[cardNumber]
  
  const {cards:{card_holder_name,card_number,expiry_date,security_code,street_address,city,state,zipcode,country},primary_method} = card.attributes
  const cardDetails = {
  primaryMethod:primary_method,
  cardNumber:card_number,
  cardholderName : card_holder_name,
  expiryDate:expiry_date,
  securityCode:security_code,
  streetAddress:street_address,
  city:city,
  state:state,
  zipCode:zipcode,
  country:{name:country},
  id:card.id,

  }

  return cardDetails
}

closePaymentModal = ()=>{
  this.setState({showPaymentModal:false,cardNumber:-1,focusedField:null,action:null})
}

handleCreateNewCard =async (values:CardDetails)=>{
  const {profileData,action} = this.state
   
  let cardDetails = {      
      account_id:profileData.account_id,      
   data: {
       attributes: {
           card_type:"credit" ,
           card_number:values.cardNumber ,
           card_holder_name:values.cardholderName ,
           expiry_date:values.expiryDate ,
           security_code:values.securityCode ,
           email:profileData.email ,
          
           phone_number:profileData.phoneNumber ,
          
           street_address:values.streetAddress ,
           zipcode:values.zipCode ,
           city:values.city ,
           state:values.state ,
           country:values.country.name 
       }
   } 
  }

  const apiUrl = action !== "add" ? `${configJSON.updateCardDetailsAPIEndpoint}/${values.id}` : configJSON.createCardAPIEndpoint
  const apiMethod = action !== "add" ? configJSON.httpPutMethod : configJSON.httpPostMethod
  

  const header = {
    token: await getStorageData("token"),
    "Content-Type": configJSON.validationApiContentType,
    "Authenticate-User":true
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  if(action === "add"){
    this.createNewCardAPICallId = requestMessage.messageId
  }
  else{
    this.updateCardDetailsAPICallId = requestMessage.messageId
  }
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    apiUrl
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(cardDetails)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    apiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);

}

handleMakePrimaryMethod = async(cardId:number)=>{

  const cardDetails = {
    type:"card",
    id:cardId,
    account_id:this.state.profileData.account_id
  }
  
  const header = {
    token: await getStorageData("token"),
    "Content-Type": configJSON.validationApiContentType,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.makePrimaryMethodAPICallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.makePrimaryPaymentMethodAPIEndpoint}`    
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(cardDetails)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.patchMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;


}

handleDeleteCard =async () => {
  const {cardIdToDelete} = this.state;
  const header = {
    token: await getStorageData("token")
  };

  const deleteCardMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.deleteCardAPICallId = deleteCardMsg.messageId;

  deleteCardMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.deleteCardAPIEndpoint}/${cardIdToDelete}`
  );

  deleteCardMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  deleteCardMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.deletePassengerMethod
  );

  runEngine.sendMessage(deleteCardMsg.id, deleteCardMsg);
};

handleDeletePassengerAndCard=()=>{
  const {isPassengerDeleting} = this.state;
  if(isPassengerDeleting){
    this.deletePassenger();
  }else{  
    this.handleDeleteCard();
  }
}


handleUpdateEmailModal = (status:boolean)=>{

  this.setState({updateEmailModal:status})
}

handleUpdateEmail = async (value:string) => {
  const {profileData : {account_id}} = this.state;
  const emailData = {
    id : account_id,
    data:{
      email:value
    }
  }

  const header = {
    token: await getStorageData("token"),
    "Content-Type": configJSON.validationApiContentType,    
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.updateEmailAPICallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.updateEmailAPIEndpoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(emailData)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.patchMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

handleAPIResponse = (apiRequestCallId:string,responseJson:{message:DynamicStringObject})=>{
  if(apiRequestCallId === this.createNewCardAPICallId){
    this.setState({showPaymentModal:false,cardNumber:-1,focusedField:null},()=>{
      this.handleTriggerAlertMessage("Card added successfully")
      this.getClientDetails()
    })
  }      

  else if(apiRequestCallId === this.updateCardDetailsAPICallId){
    this.closePaymentModal()
    this.handleTriggerAlertMessage("Changes saved successfully")
    this.getClientDetails()
  }

  
  else if(apiRequestCallId === this.updateEmailAPICallId){
    this.setState({updateEmailModal:false,modalData:"Email",showEmailSentModal:true})
  }

}

handleTripType = (tripType:string)=>{

  if(tripType === "one_way"){
    return "One Way"
  }
  else if(tripType === "round_trip"){
    return "Round Trip"

  }
  else{
    return "Multi Leg"
  }
}
handleColorByStatus = (status:string)=>{

  if(status === "outstanding" || status === "vendor_pending" ){
    return "rgba(255, 184, 51, 1)"
  }
  else if(status === "vendor_cancelled" || status === "trip_cancelled"){
    return "rgba(248, 113, 113, 1)"
  }
  
  else{
    return "rgba(32, 231, 14, 1)"
  }

}

handleStatus = (status:string)=>{

  if(status === "outstanding" ){
    return "Outstanding"
  }
  else if(status === "vendor_cancelled"){
    return "Vendor Cancelled"
  }
  
  else if(status === "paid"){
    return "Paid"
  }
  else if(status === "vendor_pending"){
    return "Vendor Pending"
  }
  else if(status === "trip_cancelled"){
    return "Trip Cancelled"

  }

  else {
    return "Vendor Confirmed"
  }

}

convertFileToBase64 = (file: File): Promise<FileData> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64File: FileData = { filename: file.name, data: reader.result };
      resolve(base64File);
    };
  });
};

uploadPassport =async (files:File[]|FileValues[])=>{
  const base64Files: FileData[] = await Promise.all((files as File[]).map((file) => this.convertFileToBase64(file)));
  return base64Files
}

handleProfile = (picture:File)=>{
  this.setState({profilePicture:picture})
}

handleEditProfilePictureModal = (action:boolean)=>{
  
  this.setState({editPictureModal:action})
  
}

handleCardProvider = (card_type:string)=>{
  switch (card_type) {
    case 'MasterCard':
        return masterCard;
    case 'Visa':
        return visa;
    case 'Amex':
        return americanExpress;
    default:
        return defaultCard;
}

}

  getCountryFlag = (countryCode: string) => {
    return countryCode
      .toUpperCase()
      .replace(/./g, (char: string) => String.fromCodePoint(127397 + char.charCodeAt(0)))
  }

  // Customizable Area End
}
