import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ApiResponseJson, ProfileData,imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {handleNavigation} from "../../../components/src/Navigator";
import {setStorageData} from "../../../framework/src/Utilities";
import {handleRole, registerRoute} from "../../../components/src/utils";
import { FormikProps } from "formik";
import Cookies from 'js-cookie';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  isTypePassword:boolean;
  isSnackBarVisible:boolean;
  focusLabelKey:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginCallApiId: string = '';
  formikMethods:FormikProps<unknown>|null = null;
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      isTypePassword:false,
      isSnackBarVisible:false,
      focusLabelKey: ''
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Logical Functions
   getUserRole=()=> {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get("role");
  }
  handlePasswordType=()=>{
    this.setState({isTypePassword: !this.state.isTypePassword});
  }


  loginUser = async (values: {email:string,password:string, is_client: string}) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      platform: "desktop"
    };
    this.loginCallApiId = requestMessage.messageId;

    let formData = new FormData();
     
    formData.append('data[type]','email_account');
    for (let key in values) {
      formData.append(`data[attributes][${key}]`, values[
    key as keyof typeof values]);
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginEmailAccountEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleForgotPassword = ()=>{
    handleNavigation("/ForgotPassword",this.props,this.send)
  }

  isCreateAccountGrey = (
    email: string,
    password: string,
  ) => {
    if (email.length && password.length) {
      return true;
    }
    return false;
  };

  navigationToRegister=()=>{
    handleNavigation(`${registerRoute}`,this.props,this.send);
  }

 handleCloseSnackBar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isSnackBarVisible:false});
  };

  // Logical Functions End


  // Handler Functions

    handleFocus=(focusLabel='')=>{
      this.setState({focusLabelKey:focusLabel});
    }

    handleLoginResponse = (responseJson:ApiResponseJson)=>{
      if(responseJson.errors){
        this.handleLoginErrorResponse(responseJson)
      }else{
        this.handleSuccessLoginResponse(responseJson);
      }
    }

    handleLoginErrorResponse=(responseJson:ApiResponseJson)=>{
      const {errors} = responseJson;
      if(errors[0].account){
        if(errors[0].account === "Invalid password format"){
          (this.formikMethods as FormikProps<unknown>).setFieldError("password", "Password Invalid*");
        }else if(errors[0].account === 'Account not found, or not activated'){
          (this.formikMethods as FormikProps<unknown>).setFieldError("email", 'No account registered with this email address*');
        }
        else if(errors[0].account === "This account is no longer active."){
          (this.formikMethods as FormikProps<unknown>).setFieldError("email", "This account is no longer active");
        }
        else if(errors[0].account === "Your account is blocked, Please reset your password to reactivate."){
          (this.formikMethods as FormikProps<unknown>).setFieldError("password","Your account is blocked, Please reset your password to reactivate.")
        }
        else if((errors[0].account === "Admin or Super Admin can not be login in the desktop client" || errors[0].account === "Client can not be login in the desktop admin")){
          (this.formikMethods as FormikProps<unknown>).setFieldError("email","Client can not be login in the desktop admin")  
        }
      }
    }


    handleSuccessLoginResponse=(responseJson:ApiResponseJson)=>{
      if(responseJson.meta.message){
        if(responseJson.meta.message === 'logged in successfully'){
          this.setState({isSnackBarVisible:true});
          Cookies.set('status', 'in', { expires: 7 });
          if(responseJson.meta.profile_created){
            this.handleUserLoginProcess(responseJson.meta);
          }else{
            handleNavigation(`${registerRoute}`,this.props,this.send,{token:responseJson.meta.token});
          }
        } 
      }
    }

    handleUserLoginProcess=async(data:ProfileData )=>{
      await this.handleSetUserDetails(data);
      handleNavigation('/',this.props,this.send);
    }
    
    handleSetUserDetails=async(data:ProfileData)=>{
      const {account:{data:{attributes:{profile:{data:{attributes:{full_name,photo}}}}}},is_admin,is_super_admin} = data;
      await setStorageData('name',full_name);
      await setStorageData('photo',photo);
      await setStorageData('token',data.token);
      await setStorageData('role',handleRole({is_admin,is_super_admin}));
    }


    handleValidationApiResponse = (responseJson:{data:{email_validation_regexp:string}[]})=>{
      let arrayholder = responseJson.data;

      if (arrayholder && arrayholder.length !== 0) {
        let regexData = arrayholder[0];

        if (regexData) {
          this.emailReg = new RegExp(regexData.email_validation_regexp);
        }
      }
    }


    handleApiEmailLoginResponse = (responseJson:ApiResponseJson,errorReponse:ApiResponseJson)=>{
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        this.saveLoggedInUserData(responseJson);
        this.sendLoginSuccessMessage();
        this.openInfoPage();
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
        this.sendLoginFailMessage();
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }

  // Handler Functions End

  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

     
        if (apiRequestCallId === this.validationApiCallId) {
          this.handleValidationApiResponse(responseJson);
        }

        if (apiRequestCallId === this.apiEmailLoginCallId) {
          this.handleApiEmailLoginResponse(responseJson,errorReponse);
        }

        if(apiRequestCallId === this.loginCallApiId){
          this.handleLoginResponse(responseJson)
        }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
