import React from "react";
import { Box, Grid,styled, InputAdornment, FormControlLabel, IconButton, Button, PopperProps, TextField } from "@material-ui/core";
import { MuiModal, GoldishSimpleButton, PaymentLabel, CustomRadio, StyledModalContent, CustomFormTextField, 
  CustomDepartueAiportAutoCompplete, CustomStyledPopper, FormSubmitButton, AddLegtTypeButton,CustomTextField,PassengerLabel, StyledGrid, PhoneNumberFieldBox, ContainerSameAsTextField, renderOptionFrom, ClientLabel, ClientValue, TopupButton, PaymentTexfields, TopupAmountBox, formatCardNumber, cleanCardNumber, BoxContainer, BoxItems, handleVendorChargedChange } from "./MuiCustomComponents";
import { theme as colors } from "./utils";
import { KeyboardArrowDown, DateRangeOutlined,RoomOutlined,SearchOutlined,ClearOutlined,DeleteOutline,SaveAlt,TimerSharp} from "@material-ui/icons";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import { Formik,} from "formik";
import moment from "moment";
import * as Yup from 'yup';
import { ICountry, IState, ICity, Country, State,City} from 'country-state-city';
import {PDF_ICON} from "./assets"
import { cardFormValidationSchema, cardValidationSchema, existingCardSchema, topupCustomCardSchema, topupHourCardsSchema, topupSchema } from "./Validations";
import { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete";
import { TopupCustomCardDetails } from "../../blocks/cfcustomskycards/src/assets";


interface  DebitCardTopupValues{
  topupAmount:string

}

interface HourCardTopupValues{
  hours:number|null,
  minutes:number|null
}

interface CustomCardTopupValues{
  trips:number|null,
  price:number|null,
}

const visa_image = require('./visa.png');
const master_image = require('./mastercard.png');
const defaultCard = require('./creditCard.png')
const americalExpess = require('./americanExpress.png');

const passengerSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()  // This will trim leading and trailing spaces
    .min(1, 'Name cannot be empty*')
    .required('Full name is required*')
    .test('is-not-only-spaces', 'Name cannot be only spaces', value => {
      return value && value.trim().length > 0;
    }),
    lastName: Yup.string()
    .trim()  // This will trim leading and trailing spaces
    .min(1, 'Name cannot be empty*')
    .required('Last Name is required*')
    .test('is-not-only-spaces', 'Name cannot be only spaces', value => {
      return value && value.trim().length > 0;
    }),
    dateOfBirth: Yup.string()
    .required('Date of birth is required')
    .test('is-past', 'Date of birth must be in the past*', (value) => {
      const currentDate = new Date();
      const inputDate = new Date(value);
      return inputDate < currentDate;
    }),
    gender:Yup.string().required('Gender is required*'),
    passportNumber:Yup.string().required('Passport Number is required*'),
    phoneNumber: Yup.string().required('Phone number is required*'),
    dateOfExpiration: Yup.string()
      .required('Date of expiration is required*')
      .test('is-valid-date', 'Date of expiration is not a valid date*', (value) => {
        return !isNaN(new Date(value).getTime());
      }),
    dateOfInsurance: Yup.string()
      .required('Date of insurance is required*')
      .test('is-before-expiration', 'Date of insurance must be before the date of expiration*', function(value) {
        const { dateOfExpiration } = this.parent;
        const insuranceDate = new Date(value);
        const expirationDate = new Date(dateOfExpiration);
        return insuranceDate < expirationDate;
      })
      .test('is-valid-date', 'Date of insurance is not a valid date*', (value) => {
        return !isNaN(new Date(value).getTime());
      }),
    countryOfIssue:Yup.string().required('Country of Issue is required*'),
    emailId: Yup.string()
    .trim()
    .email('Invalid email*')
    .required('Email is required*'),
    address:Yup.string()
    .trim()  // This will trim leading and trailing spaces
    .min(1, 'Address cannot be empty*')
    .required('Address is required*')
    .test('is-not-only-spaces', 'Address cannot be only spaces', value => {
      return value && value.trim().length > 0;
    }),
    weight: Yup.number()
    .typeError('Weight must be a number')
    .min(1, 'Weight cannot be empty*')
    .required('Weight is required*'),
    passportFile: Yup.array()
    .nullable()
    .required('Passport is required')
    .test('fileArrayLength', 'At least one file is required', (value) => {
      return value && value.length > 0;
    }),

});

const phoneValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string().required('Phone number is required*'),
  fullName: Yup.string()
    .trim()  // This will trim leading and trailing spaces
    .min(1, 'Name cannot be empty*')
    .required('Full name is required*')
    .test('is-not-only-spaces', 'Name cannot be only spaces', value => {
      return value && value.trim().length > 0;
    }),
  preferredAirport: Yup.object().shape({
    name: Yup.string()
      .required("Preffered Airport is required*")
      .min(1, "Preffered Airport can't be empty")
  })
    .nullable()
    .required('Preffered Airport is required*'),
  emailId: Yup.string()
    .trim()
    .email('Invalid email*')
    .required('Email is required*')
});



const formatDate = (date:Date|null,cardFormat=false) => {
  return date?cardFormat?moment(date).format("MM/YY"): moment(date).format('MMM D, YYYY'):"" 
};

const CustomFormControlLabel = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label.Mui-disabled":{
    color:"#fff"
  }
})

const radioStyle = (actionType:string,gender:string,selectedGender:string)=>{
  if(actionType === "show" && gender != selectedGender.toLowerCase()){
    return {display:"none"}
  }
  else{
    return {}
  }
}

const today = new Date();
today.setDate(today.getDate());

const getCountryFlag = (countryCode:string)=>{
  return countryCode
    .toUpperCase()
    .replace(/./g, (char:string) => String.fromCodePoint(127397 + char.charCodeAt(0)))

}

const countries = Country.getAllCountries()

const CancelAndSaveChanges = ({ cancel, submit = () => null, isDisabled = false,section = "admin",isSaving=false }: { cancel: () => void, submit?: () => void, isDisabled?: boolean,section?:string,isSaving?:boolean }) => {
  const saveButton = isSaving ? "Saving..." : 'Save'
 return(section==="admin"?<>
 <FormSubmitButton fullWidth disabled={isDisabled} onClick={()=>submit()} data-testid="save_changes_button">{saveButton}</FormSubmitButton>
 <AddLegtTypeButton fullWidth onClick={() => cancel()} data-testid="close_modal">Cancel</AddLegtTypeButton>
 </>: <Box mt={3}>
    <FormSubmitButton fullWidth disabled={isDisabled} onClick={()=>submit()} data-testid="save_changes_button">{saveButton}</FormSubmitButton>
    <AddLegtTypeButton fullWidth onClick={() => cancel()} style={{marginTop:"1.7rem"}} data-testid="close_modal">Cancel</AddLegtTypeButton>
    </Box>
    )
}

const renderStateCountryCityOption = (option: any) => {
  const countryFlag = getCountryFlag(option.isoCode)
  return (
    <Box className={`register_airport_option`}>
      <Box fontSize={"1.5rem"}>
        {countryFlag}
      </Box>
      <Box>{option.name}</Box>
    </Box>
  )
}



const handleRemovePassportFile = (files:File[],index:number)=>{
  files.splice(index,1)
  return files
}

const getInputStyle = (fieldName:string|null,actionType:string)=>{  
  return(actionType==="show"?{border:"none"}:fieldName === "phoneNumber"?{border:"1px solid #FFD17A !important"}:{ padding: "15px 14px 15px 70px"})
}

const getModalTitle = (actionType:string,section:string)=>{

  if(section==="client"){
    if(actionType === "add"){
      return "Add New Passenger"
    }
    else if(actionType === "edit"){
      return "Edit Passenger Details"
    }
    else {
      return "Passenger Details"      
    }
  }

  else{
    return "Add Passenger"
  }
}

 const getOptionLabel = (option:{name:string|null,city:string|null,code:string|null,countryCode:string})=>{

  if(option.city){
    return `${option.code} - ${option.name}, ${option?.countryCode}`
  }
  else{
    return option.name
  }

}

const passengerInitialValues = 
{
  firstName: "", lastName: "", dateOfBirth: "", gender: "", passportNumber: "", dateOfInsurance: "", dateOfExpiration: "",
  countryOfIssue: "", phoneNumber: "",countryCode:"",fullNumber:"",emailId: "", address: "", weight: "",passportFile:[]
}

const handleFileDrop = (event:React.DragEvent<HTMLElement>) => {
  event.preventDefault()
  const files = Array.from(event.dataTransfer.files);
};

const AddPassengerModal = ({isDisable=false, isOpen, cancel,children,uploadIcon,initialValues=passengerInitialValues,submit=()=>{},
focusField=(fieldName:string|null)=>{},focusedField="",section="admin",actionType="add",passportRef=null,editPassengerInfo=()=>{},deletePassenger=()=>{}}: any) => {
  const countries = Country.getAllCountries()
  const today = new Date();
  today.setDate(today.getDate());        
  return (
    <>
      {children}
      <MuiModal
        open={isOpen}
      >
        <StyledModalContent
          className='large_modal_styling booking_section'
          section={section}                   
        >
          <Box className='norder_detail_view_heading_parent'mb={2}>
            <GoldishSimpleButton
              style={{ color:"white", textTransform: "capitalize",fontSize:"1.8rem" }}
            >
              {getModalTitle(actionType,section)}             
            </GoldishSimpleButton>
          </Box>
          <Formik
              onSubmit={(values) => {
                let formValue = values;
                formValue.dateOfBirth = convertToDayMonthYear(values.dateOfBirth)
                formValue.dateOfInsurance = convertToDayMonthYear(values.dateOfInsurance)
                formValue.dateOfExpiration = convertToDayMonthYear(values.dateOfExpiration)
                submit(formValue)
              }}
            validateOnChange
            validateOnBlur
            initialValues={initialValues}
            validationSchema={passengerSchema}
            enableReinitialize>
            {({values,handleChange,setFieldValue,touched,setFieldError,errors,handleSubmit}:any) => (
              
              <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="firstName"}>First Name</PassengerLabel>
                  <CustomTextField
                    name={"firstName"}
                    disabled={actionType==="show"}
                    isFocusedTypeInput={focusedField==="firstName"}
                    placeholder='Enter first name'
                    fullWidth
                    value={values.firstName}
                    multiline
                    onChange={(event:any)=>setFieldValue("firstName",event.target.value)}
                    data-test-id="firstName"                    
                    id="firstName"
                    onFocus={()=>focusField("firstName")}
                    onBlur={()=>focusField(null)}
                    showError={touched.firstName && errors.firstName}
                    actionType={actionType}
                    section={section}
                    />
                    {touched.firstName && (
                      <Box mt={1} color={"red"} data-test-id="errorField">{errors.firstName}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="lastName"}>Last Name</PassengerLabel >
                  <CustomTextField 
                  placeholder='Enter last name'
                  actionType={actionType}
                  multiline
                  disabled={actionType==="show"} 
                  fullWidth
                  value={values.lastName}
                  onChange={handleChange}
                  name={"lastName"}
                  data-test-id="lastName"
                  isFocusedTypeInput={focusedField==="lastName"}
                  onFocus={()=>focusField("lastName")}
                  onBlur={()=>focusField(null)}
                  showError={touched.lastName && errors.lastName}
                  section={section}/>
                  {touched.lastName && (
                      <Box mt={1} color={"red"}>{errors.lastName}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="dateOfBirth"}>Date of Birth</PassengerLabel >
                  <StyledGrid popperWidth = {document.querySelector('.react-datepicker-wrapper')?.clientWidth}>
                  <CustomTextField
                    name={"dateOfBirth"}
                    disabled={actionType==="show"}
                    isFocusedTypeInput={focusedField==="dateOfBirth"}
                    placeholder='MM/DD/YYYY'
                    fullWidth
                    autoComplete='off'
                    value={values.dateOfBirth}
                    multiline
                    onChange={(event:any)=> handleDateChange(event,setFieldValue)}
                    data-test-id="dateOfBirth"                    
                    id="dateOfBirth"
                    onFocus={()=>focusField("dateOfBirth")}
                    onBlur={()=>focusField(null)}
                    showError={touched.dateOfBirth && errors.dateOfBirth}
                    actionType={actionType}
                    section={section}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          <DateRangeOutlined />
                        </InputAdornment>
                      ),
                    }}
                    />
                  </StyledGrid>
                  {touched.dateOfBirth && (
                      <Box mt={1} color={"red"}>{errors.dateOfBirth}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel >Gender</PassengerLabel>
                  <Box className="order_detail_head_edit" height={'4rem'}>
                    <CustomFormControlLabel onChange={handleChange} disabled={actionType==="show"} style={radioStyle(actionType,"male",values.gender)} name="gender" value="Male" checked={values.gender?.toLowerCase()=="male"} data-test-id="radioButton" control={<CustomRadio  />} label="Male" />
                    <CustomFormControlLabel onChange={handleChange} disabled={actionType==="show"} style={radioStyle(actionType,"female",values.gender)} name="gender" value="Female" checked={values.gender?.toLowerCase() == "female"} control={<CustomRadio />} label="Female" />
                    <CustomFormControlLabel onChange={handleChange} disabled={actionType==="show"} style={radioStyle(actionType,"other",values.gender)} name="gender" value="Other" checked={values.gender?.toLowerCase() =="other"} control={<CustomRadio />} label="Other" />
                  </Box>
                  {touched.gender && (
                      <Box mt={"-10px"} color={"red"}>{errors.gender}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="passportNumber"}>Passport Number</PassengerLabel >
                  <CustomTextField
                  isFocusedTypeInput={focusedField==="passportNumber"}
                  onFocus={()=>focusField("passportNumber")}
                  onBlur={()=>focusField(null)}
                  name="passportNumber"
                  multiline
                  placeholder='Enter passport number' 
                  fullWidth
                  value={values.passportNumber}
                  onChange={handleChange}
                  actionType={actionType}
                  data-test-id="passportNumber"
                  disabled={actionType==="show"}
                  showError={touched.passportNumber && errors.passportNumber}
                  section={section} />
                  {touched.passportNumber && (
                      <Box mt={1} color={"red"}>{errors.passportNumber}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="dateOfInsurance"}>Date of Issuance</PassengerLabel>
                  <StyledGrid popperWidth = {document.querySelector('.react-datepicker-wrapper')?.clientWidth}>
                  <CustomTextField
                    name={"dateOfInsurance"}
                    autoComplete='off'
                    disabled={actionType==="show"}
                    isFocusedTypeInput={focusedField==="dateOfInsurance"}
                    placeholder='MM/DD/YYYY'
                    fullWidth
                    value={values.dateOfInsurance}
                    multiline
                    onChange={(event:any)=> handleDateChange(event,setFieldValue,"dateOfInsurance")}
                    data-test-id="dateOfInsurance"                    
                    id="dateOfInsurance"
                    onFocus={()=>focusField("dateOfInsurance")}
                    onBlur={()=>focusField(null)}
                    showError={touched.dateOfInsurance && errors.dateOfInsurance}
                    actionType={actionType}
                    section={section}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          <DateRangeOutlined />
                        </InputAdornment>
                      ),
                    }}
                    />
                  </StyledGrid>
                  {touched.dateOfInsurance && (
                      <Box mt={1} color={"red"}>{errors.dateOfInsurance}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="dateOfExpiration"}>Date of Expiration</PassengerLabel >
                  <StyledGrid popperWidth = {document.querySelector('.react-datepicker-wrapper')?.clientWidth}                   >
                  <CustomTextField
                    name={"dateOfExpiration"}
                    disabled={actionType==="show"}
                    isFocusedTypeInput={focusedField==="dateOfExpiration"}
                    placeholder='MM/DD/YYYY'
                    autoComplete='off'
                    fullWidth
                    value={values.dateOfExpiration}
                    multiline
                    onChange={(event:any)=> handleDateChange(event,setFieldValue,"dateOfExpiration")}
                    data-test-id="dateOfExpiration"                    
                    id="dateOfExpiration"
                    onFocus={()=>focusField("dateOfExpiration")}
                    onBlur={()=>focusField(null)}
                    showError={touched.dateOfExpiration && errors.dateOfExpiration}
                    actionType={actionType}
                    section={section}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          <DateRangeOutlined />
                        </InputAdornment>
                      ),
                    }}
                    />
                  </StyledGrid>
                  {touched.dateOfExpiration && (
                      <Box mt={1} color={"red"}>{errors.dateOfExpiration}</Box>
                    )}                  
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="countryOfIssue"}>Country of Issue</PassengerLabel>
                  <CustomDepartueAiportAutoCompplete
                    section={"client"}
                    defaultValue={values.countryOfIssue}
                    disabled={actionType==="show"}
                    actionType={actionType}
                    onChange={(country: Event, newValue: any) => {setFieldValue("countryOfIssue",newValue) }} 
                    onFocus={()=>focusField("countryOfIssue")}
                    onBlur={()=>focusField(null)}  
                    isFocusedTypeInput = {focusedField==="countryOfIssue"}
                    showError={touched.countryOfIssue && errors.countryOfIssue}                 
                    ListboxProps={{ id: "regsiter_option_style" }}
                    getOptionLabel={(option: any) => option.name}
                    data-test-id="countryOfIssue"
                    name="countryOfIssue"
                    options={countries}
                    values={values.countryOfIssue?.name}
                    PopperComponent={(props: any) => (
                      <CustomStyledPopper
                        {...props}
                        zIndex={1500}
                        marginTop="1px"
                      />
                    )}
                    renderInput={(params: any) => (
                      <CustomTextField
                        {...params}                        
                        placeholder='Select Country'
                        InputProps={{
                          ...params.InputProps,
                           endAdornment: (
                            (actionType!=="show" && focusedField !== "countryOfIssue") &&  (<Box className='profile_hover_icons'>
                            <KeyboardArrowDown />
                          </Box>)                            
                          ),
                          startAdornment: (
                            <>
                            {actionType !== "show" && <Box>
                               <SearchOutlined style={{color:"#94A3B8"}} />
                            </Box>}
                            </>
                          ),
                        }}
                      />
                    )}
                    renderOption={(
                      option: any,
                      { selected }: { selected: boolean },
                    ) => <>{renderStateCountryCityOption(option)}</>}
                  />
                  {touched.countryOfIssue && (
                      <Box mt={1} color={"red"}>{errors.countryOfIssue}</Box>
                    )}                  
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="phoneNumber"}>Phone Number</PassengerLabel>
                  <PhoneNumberFieldBox
                  onFocus={()=>focusField("phoneNumber")}
                  showError={errors.phoneNumber && touched.phoneNumber}
                  actionType={actionType}
                  section={section}
                  isFocusedTypeInput={focusedField==="phoneNumber"}
                  onBlur={()=>focusField(null)}>
                  <PhoneInput
                    disabled={actionType==="show"}
                    enableSearch={true}
                    onChange={(value, data: { dialCode: string }, event, formattedValue) => {
                      const countryCode = data.dialCode; // This gives you '1' for 'United States'
                      const phoneNumber = countryCode && value.substring(countryCode.length);
                      setFieldValue("phoneNumber", phoneNumber);
                      setFieldValue("countryCode", countryCode);
                      setFieldValue("full_number",value)
                    }}
                    searchClass="search_country_code"
                    inputClass={`phone_number_inputField`}
                    localization={{noEntriesMessage: 'No Options'}}
                    data-test-id="phoneNumber"
                    dropdownClass='phone_number_dropdown'
                    containerClass='regsiter_contaitner_class'
                    searchPlaceholder="Search"
                    searchNotFound="No Options"
                    dropdownStyle={{ width:document.querySelector('.react-datepicker-wrapper')?.clientWidth,zIndex: 1,marginTop:"2px",borderTopLeftRadius:"0px",borderTopRightRadius:"2px" }}
                    containerStyle={{ zIndex: 4, position: "relative", }}
                    country='us'
                    placeholder='Enter phone number'
                    value = {values.fullNumber}
                  />
                  </PhoneNumberFieldBox>
                  {touched.phoneNumber && (
                      <Box mt={1} color={"red"}>{errors.phoneNumber}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="emailId"}>Email ID</PassengerLabel>
                  <CustomTextField
                  disabled={actionType==="show"}
                  onFocus={()=>focusField("emailId")}
                  onBlur={()=>focusField(null)}
                  isFocusedTypeInput = {focusedField==="emailId"}
                  actionType={actionType}
                  autoComplete='off'
                  section={section}
                  showError={touched.emailId && errors.emailId}                   
                  placeholder='Enter email ID'
                  data-test-id="emailId" 
                  fullWidth
                  name="emailId"
                  value={values.emailId}
                  onChange={handleChange} />
                  {touched.emailId && (
                      <Box mt={1} color={"red"}>{errors.emailId}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="address"}>Address</PassengerLabel>
                  <CustomTextField
                  disabled={actionType==="show"}
                  onFocus={()=>focusField("address")}
                  onBlur={()=>focusField(null)}
                  isFocusedTypeInput = {focusedField==="address"}
                  showError={touched.address && errors.address}
                  multiline 
                  placeholder='Enter full address' 
                  fullWidth
                  data-test-id="address"
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  actionType={actionType}
                  section={section} />
                  {touched.address && (
                      <Box mt={1} color={"red"}>{errors.address}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="weight"}>Weight</PassengerLabel>
                  <CustomTextField 
                  disabled={actionType==="show"}
                  placeholder='Enter weight' 
                  fullWidth
                  type='number'
                  actionType={actionType}
                  section={section}
                  name="weight"
                  value={values.weight} 
                  data-test-id="weight"                 
                  onChange={handleChange}
                  onFocus={()=>focusField("weight")}
                  onBlur={()=>focusField(null)}
                  showError={touched.weight && errors.weight}
                  isFocusedTypeInput = {focusedField==="weight"}
                  InputProps={{
                    startAdornment: <InputAdornment style={{position:'absolute',marginLeft:'7rem'}} position="end"><Box color={'white'}>kg</Box></InputAdornment>,
                  }}
                 />
                 {touched.weight && (
                      <Box mt={1} color={"red"}>{errors.weight}</Box>
                    )}
                  
                </Grid>

                <Grid item lg={12} md={12} sm={12}>
                  {actionType!=="show" && (
                    <Box pb={2}>
                    <PaymentLabel style={{color:"rgba(148, 163, 184, 1)"}}>Upload Passport Picture</PaymentLabel>
                  <input
                  disabled={actionType==="show"}
                  type="file"
                  id="file-input"
                  ref={passportRef}
                  accept=".png, .jpg, .jpeg, .pdf"
                  style={{ display: "none"}}
                  onChange={(
                     event: React.ChangeEvent<HTMLInputElement>,
                  ) => {
                     if (
                        event.target.files
                     ) {
                      setFieldValue("passportFile", [...event.target.files,...values.passportFile]);
                       }
                    }}
                    multiple={true}
                  />
                  <Box
                    className="order_detail_upload_box"
                    data-test-id={"uploadPassport"}
                    style={{ flexDirection: 'column',border:touched.passportFile && errors.passport ? "1px dashed #f87171":"1px dashed #fff" }}
                    onClick={(_event: React.MouseEvent<HTMLElement>) => { passportRef.current?.click() }}
                    onDragOver={(event: React.DragEvent<HTMLElement>) => event.preventDefault()}
                    onDrop={(event: React.DragEvent<HTMLElement>) => {
                      event.preventDefault()
                      const files = Array.from(event.dataTransfer.files);
                      setFieldValue("passportFile",[...files,...values.passportFile])
                    }}
                  >

                      {uploadIcon}
                      <Box>
                        <Box>Drag Or Click To Upload</Box>
                      </Box>
                  </Box>
                  {touched.passportFile && (
                      <Box mt={1} color={"red"}>{errors.passportFile}</Box>
                    )}
                    </Box>
                  ) }
                  {values.passportFile.length > 0 && (
                    <>
                    <PassengerLabel>Passport Picture</PassengerLabel >
                    <>
                    {values.passportFile.map((passport:File,index:number)=>(
                      <>
                      <Box className="order_detail_doc_parent" justifyContent={"space-between"}>
                      <Box marginLeft={'1rem'} display={"flex"} gridColumnGap={"1rem"}>
                        <Box pt={"0.5rem"}>
                        <PDF_ICON unique_id={"key"}/>
                        </Box>
                        <Box fontSize={"1.4rem"}>{passport.name}</Box>
                      </Box>
                      <Box>
                      {actionType !== "show" && <IconButton onClick={()=> setFieldValue("passportFile",handleRemovePassportFile(values.passportFile,index))} data-testid="delete_doc" className = 'order_detail_icon_buton' ><DeleteOutline style={{color:'#DC2626'}}/></IconButton>}
                      {actionType === "show" && <IconButton className = 'order_detail_icon_buton'><SaveAlt style={{color:colors.SAVE_DARK_COLOUR}}/></IconButton>}
                    </Box>
                    </Box>
                    
                      </>
                    ))}
                  </>
                   </>
                  )}
                </Grid>

                
                <Grid sm={12}>                  
                </Grid>
              </Grid>
              <Grid item sm={12} md={12} lg={12}>
                {actionType === "show" ?(
                  <Box display={"flex"} justifyContent={"space-around"} mt={3}>
                  <AddLegtTypeButton style={{flexGrow:1,marginRight:"1rem"}} onClick={()=>cancel()}>Cancel</AddLegtTypeButton>
                  <AddLegtTypeButton style={{border:"1px solid #f87171",flexGrow:1,marginRight:"1rem",color:"#F87171"}} onClick={()=>deletePassenger(initialValues.passengerId)}>Delete</AddLegtTypeButton>
                  <FormSubmitButton style={{flexGrow:1}} onClick={()=>editPassengerInfo("edit")}>Edit</FormSubmitButton>                 
                  </Box>):<CancelAndSaveChanges cancel={()=>cancel()} submit={()=>handleSubmit()} section={section} isDisabled={isDisable}/>
                 }
              </Grid>
              
              </form>
            )}
          </Formik>          
        </StyledModalContent>
      </MuiModal>
    </>
  )
}

const handleDateChange = (event:React.ChangeEvent<HTMLInputElement>,setDate:any,keyName='dateOfBirth') => {
  let value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
  if (value.length > 8) return; // Prevent more than 8 digits (mmddyyyy)

  // Insert slashes at the appropriate positions
  if (value.length >= 3 && value.length <= 4) {
    value = `${value.slice(0, 2)}/${value.slice(2)}`;
  } else if (value.length > 4) {
    value = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4)}`;
  }

  setDate(keyName,value);
};

const convertToMMDDYYYY = (dateString:string) => {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedMonth}/${formattedDay}/${year}`;
};

const convertToDayMonthYear = (dateString:string) => {
  const [month, day, year] = dateString.split('/').map(Number);
  const date = new Date(year, month - 1, day); // month is 0-indexed
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", 
                      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthName = monthNames[date.getMonth()];
  return `${day} ${monthName} ${year}`;
};

const AddNewClientModal = ({ isOpen, cancel, children,getSearchedAirports,airports,focusedField,setFocusedField,createNewProfile,clientForm,register=false, }: any) => {
  let airportDebounceTimeoutId: ReturnType<typeof setTimeout> | null = null;
  const handleAirportChange = (valueToSearch:string) => { 
    if(valueToSearch.length <= 2){
      return
    }  
    if (airportDebounceTimeoutId) clearTimeout(airportDebounceTimeoutId);

    airportDebounceTimeoutId = setTimeout(() => {
      getSearchedAirports(valueToSearch);
    },500);
  };
  return (
    <>
      {children}
      <MuiModal
        open={isOpen}
      >
        <StyledModalContent
          className='large_modal_styling booking_section'
          section = {"client"}          
        >
          <Box className='order_detail_view_heading_parent'>
            <GoldishSimpleButton
              style={{ color: "white", textTransform: "capitalize" }}
            >
              {register ? "Add New Client" : "Register New Client"}
            </GoldishSimpleButton>
          </Box>
          
          <Formik
            onSubmit={(values)=>{              
              createNewProfile(values)
            }}
            validateOnChange
            validateOnBlur
            initialValues={{phoneNumber: "", countryCode: "", fullName: "", preferredAirport: null, emailId: "" }}
            validationSchema={phoneValidationSchema}
            enableReinitialize
            innerRef={clientForm}
               
            >
            {({values,handleChange,setFieldValue,touched,setFieldError,errors,handleSubmit}:any) => (
              
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12}>
                    <PassengerLabel isFocused={focusedField==="fullName"}>Full Name</PassengerLabel>
                    <CustomTextField
                      placeholder='Full Name'
                      fullWidth                      
                      id="fullName"
                      name="fullName"
                      onChange={handleChange}
                      value={values.fullName}
                      isFocusedTypeInput={focusedField==="fullName"}
                      showError={touched.fullName && errors.fullName}
                      editInfo={true}
                      section = {"client"}
                      autoComplete='off'
                      onFocus={()=>setFocusedField("fullName")}
                      onBlur={()=>setFocusedField(null)}
                      actionType = {"add"}
                      data-testid="fullName" />
                    {touched.fullName && (
                      <Box className="register_valid_error">
                        {errors.fullName}
                      </Box>
                    )}
                  </Grid>

                  <Grid item lg={6} md={6} sm={12}>
                    <PassengerLabel isFocused={focusedField==="emailId"}>Email ID</PassengerLabel>
                    <CustomTextField
                      placeholder='Email ID' fullWidth
                      onChange={handleChange} id="email" 
                      name="emailId"
                      autoComplete='off'
                      value={values.emailId}
                      isFocusedTypeInput={focusedField==="emailId"}
                      onFocus={()=>setFocusedField("emailId")}
                      section = {"client"}
                      onBlur={()=>setFocusedField(null)}
                      editInfo={true}
                      showError={touched.emailId && errors.emailId}
                      actionType = {"add"}
                      data-testid="email" />
                    {touched.emailId && (
                      <Box className={"register_valid_error"}>{errors.emailId}</Box>
                    )}
                  </Grid>

                  <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel isFocused={focusedField==="phoneNumber"}>Phone Number</PassengerLabel>
                  <PhoneNumberFieldBox                                                      
                  showError={errors.phoneNumber && touched.phoneNumber}
                  actionType={"add"}
                  isFocusedTypeInput={focusedField==="phoneNumber"}
                  className="phoneNumberField">                                    
                  <PhoneInput                    
                    enableSearch={true}
                    searchNotFound="No Options"
                    onChange={(value, data: { dialCode: string }, event, formattedValue) => {
                      const countryCode = data.dialCode;
                      const phoneNumber = countryCode && value.substring(countryCode.length);
                      setFieldValue("phoneNumber", phoneNumber);
                      setFieldValue("countryCode", countryCode);
                      setFieldValue("full_number",value)
                    }}
                    searchClass="search_country_code"
                    inputClass={`phone_number_inputField`}
                    data-test-id="phoneNumber"
                    dropdownClass='phone_number_dropdown'
                    containerClass='regsiter_contaitner_class'
                    onFocus={()=>setFocusedField("phoneNumber")}
                    onBlur={()=>setFocusedField(null)}
                    searchPlaceholder="Search"
                    localization = {{noEntriesMessage: 'No countries found',}}
                    dropdownStyle={{ width:document.querySelector('.phone_number_inputField')?.clientWidth,zIndex: 0,marginTop: "2px",borderTopLeftRadius:"0px",borderTopRightRadius:"0px" }}
                    containerStyle={{ zIndex: 4,position: "relative" }}
                    country='us'
                    placeholder='Enter phone number'
                    value = {values.fullNumber}
                  />
                  </PhoneNumberFieldBox>
                  {touched.phoneNumber && (
                      <Box mt={1} color={"red"}>{errors.phoneNumber}</Box>
                    )}
                </Grid>

                  <Grid item lg={6} md={6} sm={12}>
                    <PassengerLabel isFocused={focusedField === "preferredAirport"}>Preferred Departure Airport</PassengerLabel>
                    <CustomDepartueAiportAutoCompplete
                      onChange={(airport: Event, newValue: any) => { setFieldValue("preferredAirport", newValue) }}
                      isFocusedTypeInput={focusedField==="preferredAirport"}
                      showError={touched.preferredAirport && errors.preferredAirport}
                      actionType = {"add"}
                      onFocus={()=>setFocusedField("preferredAirport")}
                      onBlur={()=>setFocusedField(null)}
                      data-testId="airport"
                      PopperComponent={(props: any) => (
                        <CustomStyledPopper highIndex={9999}{...props} top="calc(100% + 8px)" left={0} marginTop={"1px"}/>
                      )}
                      ListboxProps={{ id: "regsiter_option_style", style: { zIndex: 9999 } }}
                      getOptionLabel={(option: any) => getOptionLabel(option)}
                      filterOptions={(option:any)=>option}
                      options={airports}
                      renderOption={(option: any, { selected }: any) => renderOptionFrom(option, selected)}
                      value={values.preferredAirport}
                      data-testid="airport"
                      renderInput={(params: any) => (
                        <CustomFormTextField
                          {...params}
                          placeholder='Select Preferred Departure Airport'
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              focusedField !== "preferredAirport" && <KeyboardArrowDown/>
                            ),
                            
                          }}
                          onChange={(event:React.ChangeEvent<HTMLInputElement>)=>handleAirportChange(event.target.value)}
                        />
                      )}
                    />
                    {touched.preferredAirport && (
                      <Box className="register_valid_error">
                        {errors.preferredAirport}
                      </Box>
                    )}
                  </Grid>
                  <Grid md={12} lg={12} sm={12}>              
                  <Box mt={2} pr={1.1} pl={1}>
                  <FormSubmitButton fullWidth onClick={()=>handleSubmit()} data-testid="save_changes_button">Create Account</FormSubmitButton>
                  <AddLegtTypeButton fullWidth onClick={() => cancel()} style={{marginTop:"1.7rem"}} data-testid="close_modal">Cancel</AddLegtTypeButton>
                 </Box>                 
                  </Grid>                 
          </Grid>                            
              </form>
            )}
          </Formik>
        </StyledModalContent>
      </MuiModal>
    </>
  )
}

const getPaymentMethodTitle = (action:string)=>{

  if(action === "add"){
    return "Add Credit/Debit Card"
  }

  else if(action === "edit"){
    return "Edit Credit/Debit Card"
  }

  else{
    return "Credit/Debit Card Details"
  }

}

const cardDetails = {
  cardNumber:"",
  cardholderName : "",
  expiryDate:"",
  securityCode:"",
  streetAddress:"",
  city:"",
  state:"",
  zipCode:"",
  country:{name:""},
  
}


const AddPaymentMethodModal = ({actionType="show",showModal = false,cancel=()=>{},submit=()=>{},openPaymentModal=()=>{},initialValues=cardDetails,
focusedField,focusField=()=>{},createCard = ()=>{},makePrimaryPaymentMethod= ()=>{},primaryMethod=false,deleteCard=()=>{},cardFormik}:any)=>{ 
  
  return(
    <>
    <MuiModal
        open={showModal}
      >
        <StyledModalContent
          className='large_modal_styling booking_section'
          section={"client"}                             
        >
          <Box className='norder_detail_view_heading_parent'mb={2}>
            <GoldishSimpleButton
              style={{ color:"white", textTransform: "capitalize",fontSize:"1.8rem" }}
            >
              {getPaymentMethodTitle(actionType)}
            </GoldishSimpleButton>
          </Box>
          <Formik
            onSubmit={(values)=>createCard(values)}
            validateOnChange
            validateOnBlur
            innerRef={cardFormik}
            initialValues={initialValues}
            validationSchema={cardValidationSchema}            
            enableReinitialize>
            {({values,handleChange,setFieldValue,touched,setFieldError,errors,handleSubmit}:any) => (
              
              <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12}>
                  <PassengerLabel card={true}>Card Number</PassengerLabel>
                  <CustomTextField
                    name={"cardNumber"}
                    actionType={actionType}
                    section={"client"}
                    placeholder='Enter Card Number'
                    fullWidth
                    value={formatCardNumber(values.cardNumber)}
                    multiline
                    isFocusedTypeInput={focusedField==="cardNumber"}
                    onFocus={()=>focusField("cardNumber")}
                    onBlur={()=>focusField(null)}
                    onChange={(event:React.ChangeEvent<HTMLInputElement>)=>setFieldValue("cardNumber",event.target.value)}
                    disabled={actionType==="show"||actionType==="edit"}
                    data-test-id="cardNumber"                    
                    id="cardNumber"                   
                    showError={touched.cardNumber && errors.cardNumber}
                    InputProps={{
                      inputProps : {
                        maxLength:19
                      }
                     }}                    
                    />                    
                    {touched.cardNumber && (
                      <Box mt={1} color={"red"} data-test-id="errorField">{errors.cardNumber}</Box>
                    )}
                </Grid>

                <Grid item lg={12} md={12} sm={12}>
                  <PassengerLabel card={true}>Card Holder Name</PassengerLabel >
                  <CustomTextField 
                  placeholder='Enter Card Holder Name'                  
                  multiline                   
                  fullWidth
                  value={values.cardholderName}
                  onChange={handleChange}
                  disabled={actionType==="show"}
                  name={"cardholderName"}
                  data-test-id="cardholderName"                  
                  showError={touched.cardholderName && errors.cardholderName}
                  isFocusedTypeInput={focusedField==="cardHolderName"}
                  onFocus={()=>focusField("cardHolderName")}
                  onBlur={()=>focusField(null)}
                  actionType={actionType}
                  id="cardholderName"
                  section={"client"}
                  />
                  {touched.cardholderName && (
                      <Box mt={1} color={"red"}>{errors.cardholderName}</Box>
                    )}
                </Grid>

                <Grid item sm={12}>
                  <PassengerLabel card={true}>Expiry Date</PassengerLabel >
                  <CustomTextField 
                  placeholder='MM/YY'                  
                  multiline                   
                  fullWidth
                  value={values.expiryDate}
                  onChange={handleChange}
                  disabled={actionType==="show"}
                  name={"expiryDate"}
                  data-test-id="expiryDate"                  
                  showError={touched.expiryDate && errors.expiryDate}
                  isFocusedTypeInput={focusedField==="expiryDate"}
                  onFocus={()=>focusField("expiryDate")}
                  onBlur={()=>focusField(null)}
                  actionType={actionType}
                  id="expiryDate"
                  section={"client"}
                  />
                  {touched.expiryDate && (
                      <Box mt={1} color={"red"}>{errors.expiryDate}</Box>
                    )}
                </Grid>                

                

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel card={true}>Street Address</PassengerLabel>
                  <CustomTextField
                  showError={touched.emailId && errors.emailId}                   
                  placeholder='Enter Street Address'
                  data-test-id="streetAddress" 
                  fullWidth                  
                  name="streetAddress"
                  value={values.streetAddress}
                  onChange={handleChange}
                  disabled={actionType==="show"}
                  actionType={actionType}
                  section={"client"}
                  isFocusedTypeInput={focusedField==="streetAddress"}
                  onFocus={()=>focusField("streetAddress")}
                  onBlur={()=>focusField(null)} />
                  {touched.streetAddress && (
                      <Box mt={1} color={"red"}>{errors.streetAddress}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel card={true}>City</PassengerLabel>
                  <CustomTextField                  
                  showError={touched.city && errors.city}
                  isFocusedTypeInput={focusedField==="city"}
                  onFocus={()=>focusField("city")}
                  onBlur={()=>focusField(null)}
                  multiline 
                  placeholder='Enter City' 
                  fullWidth
                  data-test-id="city"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  disabled={actionType==="show"}
                  actionType={actionType}
                  section={"client"}
                   />
                  {touched.city && (
                      <Box mt={1} color={"red"}>{errors.city}</Box>
                    )}
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel card={true}>State</PassengerLabel>
                  <CustomTextField                   
                  placeholder='Enter State' 
                  multiline
                  actionType={actionType}
                  section={"client"}
                  fullWidth                  
                  name="state"
                  value={values.state} 
                  data-test-id="state"                 
                  onChange={handleChange}
                  disabled={actionType==="show"}
                  showError={touched.state && errors.state}
                  isFocusedTypeInput={focusedField==="state"}
                  onFocus={()=>focusField("state")}
                  onBlur={()=>focusField(null)}
                 />
                 {touched.state && (
                      <Box mt={1} color={"red"}>{errors.state}</Box>
                    )}
                  
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                  <PassengerLabel card={true}>Zip Code</PassengerLabel>
                  <CustomTextField                   
                  placeholder='Enter Zip Code' 
                  multiline
                  fullWidth                  
                  name="zipCode"
                  value={values.zipCode}
                  actionType={actionType}
                  section={"client"} 
                  data-test-id="zipCode"                 
                  onChange={handleChange}
                  disabled={actionType==="show"}
                  showError={touched.zipCode && errors.zipCode}
                  isFocusedTypeInput={focusedField==="zipCode"}
                  onFocus={()=>focusField("zipCode")}
                  onBlur={()=>focusField(null)}
                 />
                 {touched.zipCode && (
                      <Box mt={1} color={"red"}>{errors.zipCode}</Box>
                    )}
                  
                </Grid>

                <Grid item lg={12} md={12} sm={12}>
                  <PassengerLabel card={true}>Country</PassengerLabel>
                    <CustomDepartueAiportAutoCompplete
                      defaultValue={values.country}
                      actionType={actionType}
                      section={"client"}
                      onChange={(country: Event, newValue: any) => { setFieldValue("country", newValue) }}
                      disabled={actionType === "show"}
                      isFocusedTypeInput={focusedField === "country"}
                      onFocus={() => focusField("country")}
                      onBlur={() => focusField(null)}
                      showError={touched.country && errors.country}
                      ListboxProps={{ id: "regsiter_option_style" }}
                      getOptionLabel={(option: any) => option.name}
                      options={countries}
                      data-test-id="country"
                      name="country"
                      values={values.country}
                      PopperComponent={(props: any) => (
                        <CustomStyledPopper
                          {...props}
                          zIndex={1500}
                          marginTop="1px"
                        />
                      )}
                      renderInput={(params: any) => (
                        <CustomTextField
                          {...params}
                          placeholder="Country"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              actionType !== "show" &&(
                                <Box>
                                <SearchOutlined style={{ color: "#94A3B8" }} />
                              </Box>
                              )
                            ),
                            endAdornment: (
                              actionType !== "show" &&(
                                <Box className='profile_hover_icons'>
                                <KeyboardArrowDown />
                              </Box>
                              )
                              
                            )
                          }}

                        />
                      )}
                      renderOption={(
                        option: any,
                        { selected }: { selected: boolean },
                      ) => <>{renderStateCountryCityOption(option)}</>}
                    />
                  {touched.country && (
                      <Box mt={1} color={"red"}>{errors.country}</Box>
                    )}                  
                </Grid>
              </Grid>
              {actionType==="show"?(
                <>                
                {values.primaryMethod ?<AddLegtTypeButton style={{marginTop:"2rem",color:"#34D399",background:"rgba(52, 211, 153, 0.1)",border:"1px solid #34D399"}} fullWidth>Primary Method</AddLegtTypeButton>
                :<AddLegtTypeButton fullWidth onClick={()=>makePrimaryPaymentMethod(values.id)} style={{marginTop:"2rem"}} data-test-id="makePrimaryMethodButton">Make Primary Payment Method</AddLegtTypeButton>}
                
              <Box display={"flex"} justifyContent={"space-around"} mt={3}>
                  <AddLegtTypeButton style={{flexGrow:1,marginRight:"1rem"}} onClick={()=>cancel()} data-test-id="cancel">Cancel</AddLegtTypeButton>
                  <AddLegtTypeButton style={{color:"#F87171",border:"1px solid #f87171",flexGrow:1,marginRight:"1rem"}} onClick={()=>{deleteCard()}} data-test-id="deleteCard">Delete</AddLegtTypeButton>
                  <FormSubmitButton style={{flexGrow:1}} onClick={()=>{openPaymentModal("edit")}} data-test-id="editCardButton">Edit</FormSubmitButton>                 
                  </Box>
                  </>):
                  <CancelAndSaveChanges cancel={()=>cancel()} submit={()=>handleSubmit()} section={"client"}></CancelAndSaveChanges>                                  
                  }
              
              </form>
            )}
          </Formik>
                     
          
                            
        </StyledModalContent>
      </MuiModal>
    </>
  )
}

const debitCardTopupValue= {
  topupAmount:""

}

const hourCardTopupValue = {
  hours:null,
  minutes:null,

}

const customCardTopupValue = {
  trips:null,
  price:null
}

const TopupSkyCardModal = ({topupModal,skycardType,activeField,setActiveFieldName,topupDetails,topupRef,topup,topupCard,closeModal,topupCardWithPayment,topupNewCardRef}:any)=>{
  const {current_balance,client_name,account_id,cards,current_top_ups,aircraft,hoursBalance,from_airport,to_airport,valid_from,valid_to,additionalInfo} = topupDetails
  return(
    <MuiModal open={topupModal}>
      <StyledModalContent
      className="large_modal_styling booking_section"
      section={"client"}
      style={{padding:"1.5rem"}}>
        <Box className='booking_section'>
          <Box style={{ fontSize: "2rem",fontWeight: 600 }}>Top Up {skycardType} Sky Card</Box>
          <Formik
            initialValues={skycardType === "Debit"? debitCardTopupValue:skycardType==="Custom"?customCardTopupValue:hourCardTopupValue}
            onSubmit={(values) => {
              if(skycardType === "Debit"){
                topupCard("without",(values as DebitCardTopupValues).topupAmount,values)
              }
              else if(skycardType === "Custom"){
                topupCard("without",(values as CustomCardTopupValues).price,values,(values as CustomCardTopupValues).trips)
              }
              else{
                topupCard("without",(values as HourCardTopupValues).hours,(values as HourCardTopupValues).minutes,values)
              }
            }}
            enableReinitialize
            validateOnChange
            validateOnBlur
            validationSchema={skycardType === "Debit"?topupSchema:skycardType==="Custom"? topupCustomCardSchema:topupHourCardsSchema}
            innerRef={topupRef}
          >
            {({ handleChange, handleSubmit, errors, touched, setFieldValue, values }) => (
              <>
                {skycardType !== "Custom" ?(
                  <>
                  <Box display={"flex"} pt={2} pb={1}>
                  <Box flex={1}>
                    <ClientLabel>Account Id</ClientLabel>
                    <ClientValue>{account_id}</ClientValue>
                  </Box>
                  <Box flex={1}>
                    <ClientLabel>Client</ClientLabel>
                    <ClientValue>{client_name}</ClientValue>
                  </Box>
                  {skycardType=== "25 Hour" &&(
                    <Box flex={1}>
                    <ClientLabel>Aircraft Category</ClientLabel>
                    <ClientValue>{aircraft}</ClientValue>
                  </Box>
                  )}
                  <Box flex={1}>
                    <ClientLabel>Current Balance</ClientLabel>
                    <ClientValue>{hoursBalance?hoursBalance:current_balance}</ClientValue>
                  </Box>
                </Box>
                {skycardType === "Debit" ?(
                  <>
                    <TopupAmountBox >
                      {current_top_ups.map((topup: any) => (
                        <TopupButton selected={(values as DebitCardTopupValues).topupAmount == topup.top_up} onClick={() => setFieldValue("topupAmount", topup.top_up)} id="topupAmounts">${topup.top_up}</TopupButton>
                      ))}
                      <TopupButton selected={(values as DebitCardTopupValues).topupAmount == ""} onClick={() => setFieldValue("topupAmount", "")}>Other</TopupButton>
                    </TopupAmountBox>
                    <Box pt={2} pb={1}>
                      <PaymentTexfields
                        isFocusedTypeInput={true}
                        isErrorTouch={(touched as DebitCardTopupValues).topupAmount && (errors as DebitCardTopupValues).topupAmount}
                        variant='outlined'
                        name={"topupAmount"}
                        onChange={handleChange}
                        placeholder={"Enter amount"}
                        fullWidth
                        value={(values as DebitCardTopupValues).topupAmount}
                        autoComplete='off'
                        onBlur={() => setActiveFieldName('')}
                        onFocus={() => setActiveFieldName("topupAmount")}
                        data-test-id={"topupAmount"}
                        InputProps={{
                          inputProps: {
                            style: { padding: '0px' }
                          },
                          endAdornment: (
                            <Box>$</Box>
                          )
                        }}
                      />
                      <ErrorBoxes
                        touched={(touched as DebitCardTopupValues).topupAmount}
                        message={(errors as DebitCardTopupValues).topupAmount}
                        errors={(errors as DebitCardTopupValues).topupAmount} />

                    </Box>
                  </>

                ):
                  <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <FieldWithLabel
                        value={(values as HourCardTopupValues).hours}
                        formName={"hours"}
                        handleChange={handleChange}
                        placeholderText={"hours"}
                        label={"Add Hours"}
                        activeFieldName={activeField}
                        setActiveFieldName={setActiveFieldName}
                        isErrorTouch={(touched as HourCardTopupValues).hours && (errors as HourCardTopupValues).hours}
                      />
                      <ErrorBoxes
                        touched={(touched as HourCardTopupValues).hours}
                        message={(errors as HourCardTopupValues).hours}
                        errors={(errors as HourCardTopupValues).hours} />
                    </Grid>
                    <Grid item sm={6}>
                      <FieldWithLabel
                        value={(values as HourCardTopupValues).minutes}
                        formName={"minutes"}
                        handleChange={handleChange}
                        placeholderText={"minutes"}
                        label={"Add Minutes"}
                        activeFieldName={activeField}
                        setActiveFieldName={setActiveFieldName}
                        isErrorTouch={(touched as HourCardTopupValues).minutes && (errors as HourCardTopupValues).minutes}
                      />
                      <ErrorBoxes
                        touched={(touched as HourCardTopupValues).minutes}
                        message={(errors as HourCardTopupValues).minutes}
                        errors={(errors as HourCardTopupValues).minutes} />

                    </Grid>
                  </Grid>
                }
                  </>
                ):<BoxContainer pt={2} pb={1}>
                  
                  <BoxItems>
                  <ClientLabel>Client</ClientLabel>
                   <ClientValue>{client_name}</ClientValue>
                  </BoxItems>
                  <BoxItems>
                  <ClientLabel>Account Id</ClientLabel>
                   <ClientValue>{account_id}</ClientValue>
                  </BoxItems>
                  <BoxItems>
                  <ClientLabel>From</ClientLabel>
                   <ClientValue>{from_airport}</ClientValue>
                  </BoxItems>
                  <BoxItems>
                  <ClientLabel>To</ClientLabel>
                   <ClientValue>{to_airport}</ClientValue>
                  </BoxItems>
                  <BoxItems>
                  <ClientLabel>Aircraft Category</ClientLabel>
                   <ClientValue>{aircraft}</ClientValue>
                  </BoxItems>
                  <BoxItems>
                  <ClientLabel>Additional Info</ClientLabel>
                   <ClientValue>{additionalInfo}</ClientValue>
                  </BoxItems>
                  <BoxItems>
                  <ClientLabel>Valid From</ClientLabel>
                   <ClientValue>{valid_from}</ClientValue>
                  </BoxItems>
                  <BoxItems>
                  <ClientLabel>Valid To</ClientLabel>
                   <ClientValue>{valid_to}</ClientValue>
                  </BoxItems>
                  <BoxItems>
                      <FieldWithLabel
                        value={(values as CustomCardTopupValues).trips}
                        formName={"trips"}
                        handleChange={handleChange}
                        placeholderText={"Number of Trips "}
                        label={"Number of Trips"}
                        activeFieldName={activeField}
                        setActiveFieldName={setActiveFieldName}
                        topup={true}
                        isErrorTouch = {(touched as TopupCustomCardDetails).trips && (errors as TopupCustomCardDetails).trips}
                      />
                      <ErrorBoxes
                        touched={(touched as TopupCustomCardDetails).trips}
                        message={(errors as TopupCustomCardDetails).trips}
                        errors={(errors as TopupCustomCardDetails).trips} />
                  </BoxItems>
                  <BoxItems>
                      <FieldWithLabel
                        value={(values as CustomCardTopupValues).price}
                        formName={"price"}
                        handleChange={handleChange}
                        placeholderText={"Price"}
                        label={"Price"}
                        activeFieldName={activeField}
                        setActiveFieldName={setActiveFieldName}
                        isErrorTouch = {(touched as TopupCustomCardDetails).price && (errors as TopupCustomCardDetails).price}
                      />
                      <ErrorBoxes
                        touched={(touched as TopupCustomCardDetails).price}
                        message={(errors as TopupCustomCardDetails).price}
                        errors={(errors as TopupCustomCardDetails).price} />
                  </BoxItems>
                  </BoxContainer>}                                
              </>
            )}
          </Formik>
          <Box>
          </Box>
        </Box>
        <ChargeNewCard
        skycardType={skycardType} 
        activeField={activeField}
        setActiveFieldName={setActiveFieldName}
        topupCardWithPayment={topupCardWithPayment}
        topupNewCardRef={topupNewCardRef}/>
        <ChargeExistingCard
        skycardType={skycardType} 
        cards={cards}
        activeField={activeField}
        setActiveFieldName={setActiveFieldName}
        topupCardWithPayment={topupCardWithPayment}
        />
        <FormSubmitButton style={{background:"none",border:"1px solid #fff",color:"#fff",margin:"1rem 0"}} id="topupCard" onClick={()=>topup()}>Process Without Payment</FormSubmitButton>
        <FormSubmitButton style={{background:"none",border:"1px solid #FFD27E",color:"#FFD27E"}} onClick={()=>closeModal()} id="cancel">Cancel</FormSubmitButton>
      </StyledModalContent>

    </MuiModal>
  )
}

const cardInitialDetail = {
  amount : null,
  card_number : "",
  name_on_card : null,
  expiry : null,
  security_code : null,
  street_address : null,
  city : null,
  state : null,
  zip_code : null,
  country : null,
  
}

const ChargeNewCard = ({skycardType,activeField,setActiveFieldName,topupCardWithPayment,topupNewCardRef}:any)=>{
  return (
    <Box className='order_detail_view_cards_parent booking_section' style={{backgroundColor:'#1A1C1E',paddingTop:"1rem",paddingBottom:"2rem"}}>
    <Box className='order_detail_view_cards_head' style={{fontSize:"1.4rem",fontWeight:600}}>Charge a New Card</Box>
      <Formik
        initialValues={cardInitialDetail}
        onSubmit={(values) => {
          topupCardWithPayment(skycardType,"new",values)
        }}
        enableReinitialize
        validateOnChange
        validateOnBlur
        validationSchema={cardFormValidationSchema}
        innerRef={topupNewCardRef}
      >
        {({handleChange,handleSubmit,errors,touched,setFieldValue,values})=>(
          <>
          <Grid container>
            <Grid item sm={6}
            className="booking_section">
              <Box mb={1}>
                <FieldWithLabel
                value={values.amount}
                formName={"amount"}
                handleChange={(event:React.ChangeEvent<HTMLInputElement>)=>handleVendorChargedChange(event,setFieldValue,"amount")}
                placeholderText={"Amount"}
                label={"Amount(in $)"}
                activeFieldName={activeField}
                setActiveFieldName={setActiveFieldName}
                isErrorTouch={touched.amount && errors.amount}
                />
                <ErrorBoxes
                touched={touched.amount}
                message={errors.amount}
                errors={errors.amount}
                />
              </Box>
              <Box mb={1}>
                <FieldWithLabel
                value={formatCardNumber(values.card_number)}
                formName={"card_number"}
                handleChange={handleChange}
                placeholderText={"card number"}
                label={"Card Number"}
                activeFieldName={activeField}
                setActiveFieldName={setActiveFieldName}
                isErrorTouch={touched.card_number && errors.card_number}
                />
                <ErrorBoxes
                touched={touched.card_number}
                message={errors.card_number}
                errors={errors.card_number}/>
              </Box>
              <Box mb={1}>
                <FieldWithLabel
                value={values.name_on_card}
                formName={"name_on_card"}
                handleChange={handleChange}
                placeholderText={"Name"}
                label={"Name on Card"}
                activeFieldName={activeField}
                setActiveFieldName={setActiveFieldName}
                isErrorTouch={touched.name_on_card && errors.name_on_card}
                />
                <ErrorBoxes
                touched={touched.name_on_card}
                message={errors.name_on_card}
                errors={errors.name_on_card}/>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"} gridColumnGap={'1rem'}>
                <Box flex={1} mb={1}>
                <FieldWithLabel
                value={values.expiry}
                formName={"expiry"}
                handleChange={handleChange}
                placeholderText={"MM/YY"}
                label={"Expiry Date"}
                activeFieldName={activeField}
                setActiveFieldName={setActiveFieldName}
                isErrorTouch={touched.expiry && errors.expiry}
                />
                <ErrorBoxes
                touched={touched.expiry}
                message={errors.expiry}
                errors={errors.expiry}/>
                </Box>
                <Box flex={1}>
                  <FieldWithLabel
                    value={values.security_code}
                    formName={"security_code"}
                    handleChange={handleChange}
                    placeholderText={"Security Code"}
                    label={"Security Code"}
                    activeFieldName={activeField}
                    setActiveFieldName={setActiveFieldName}
                    isErrorTouch={touched.security_code && errors.security_code}
                  />
                  <ErrorBoxes
                    touched={touched.security_code}
                    message={errors.security_code}
                    errors={errors.security_code} />
                </Box>
              </Box>
            </Grid>
            <Grid
             item sm={6}
             style={{ paddingLeft: "1rem",}}
             className="booking_section"
            >
              <Box mb={1}>
                <FieldWithLabel
                  value={values.street_address}
                  formName={"street_address"}
                  handleChange={handleChange}
                  placeholderText={"Street Address"}
                  label={"Street Address"}
                  activeFieldName={activeField}
                  setActiveFieldName={setActiveFieldName}
                  isErrorTouch={touched.street_address && errors.street_address}
                />
                <ErrorBoxes
                  touched={touched.street_address}
                  message={errors.street_address}
                  errors={errors.street_address} />
              </Box>
              <Box mb={1}>
                <FieldWithLabel
                  value={values.city}
                  formName={"city"}
                  handleChange={handleChange}
                  placeholderText={"City"}
                  label={"City"}
                  activeFieldName={activeField}
                  setActiveFieldName={setActiveFieldName}
                  isErrorTouch={touched.city && errors.city}
                />
                <ErrorBoxes
                  touched={touched.city}
                  message={errors.city}
                  errors={errors.city} />
              </Box>
              <Box display={"flex"} justifyContent={"space-between"} gridColumnGap={'1rem'}>
                <Box flex={1} mb={1}>
                  <FieldWithLabel
                    value={values.state}
                    formName={"state"}
                    handleChange={handleChange}
                    placeholderText={"State"}
                    label={"State"}
                    activeFieldName={activeField}
                    setActiveFieldName={setActiveFieldName}
                    isErrorTouch={touched.state && errors.state}
                  />
                  <ErrorBoxes
                    touched={touched.state}
                    message={errors.state}
                    errors={errors.state} />
                </Box>
                <Box flex={1} mb={1}>
                  <FieldWithLabel
                    value={values.zip_code}
                    formName={"zip_code"}
                    handleChange={handleChange}
                    placeholderText={"Zip Code"}
                    label={"Zip Code"}
                    activeFieldName={activeField}
                    setActiveFieldName={setActiveFieldName}
                    isErrorTouch={touched.zip_code && errors.zip_code}
                  />
                  <ErrorBoxes
                    touched={touched.zip_code}
                    message={errors.zip_code}
                    errors={errors.zip_code} />
                </Box>
              </Box>
              <Box>
                <PaymentLabel isFocused={activeField === "country"}>Country</PaymentLabel>
                  <CustomDepartueAiportAutoCompplete
                    bgColor={"inherit"}
                    height={'3rem'}
                    onChange={(_event: Event, newValue: ICountry) => {
                      
                      setFieldValue("country", newValue.name);
                    }}
                    data-test-id={"country"}
                    isFocusedTypeInput={activeField === "country"}
                    showError={touched.country && errors.country}
                    onBlur={() => setActiveFieldName('')}
                    onFocus={() => setActiveFieldName("country")}
                    id='profile_country_selector'
                    PopperComponent={(props: PopperProps) => (
                      <CustomStyledPopper
                        {...props}
                        zIndex={1}
                        marginTop="1px"
                        highIndex={9999}
                      />
                    )}
                    ListboxProps={{ id: "regsiter_option_style" }}
                    getOptionLabel={(option: ICountry) => option.name}
                    options={Country.getAllCountries()}
                    renderOption={(
                      option: ICountry,
                      { selected }: { selected: boolean },
                    ) => <CountryOption option={option} />}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <PaymentTexfields
                        {...params}
                        placeholder={"Choose Country"}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Box className="profile_hover_icons">
                              <KeyboardArrowDown />
                            </Box>
                          ),
                          
                        }}
                        isErrorTouch={touched.country && errors.country}
                      />
                    )}
                  />
                
                <ErrorBoxes
                  touched={touched.country}
                  message={errors.country}
                  errors={errors.country} />
              </Box>

            </Grid>
          </Grid>
          <FormSubmitButton data-test-id={'process_payment'} onClick={() => handleSubmit()} fullWidth style={{ marginTop: '1rem' }}>Process Payment</FormSubmitButton>
          </>
        )}
      </Formik>
  </Box>
  )
}

const init_values  = {
  amount_pay:null,
  card_id:null
}

const getIcon = (card_type:string) => {
  switch (card_type) {
      case 'MasterCard':
          return master_image;
      case 'Visa':
          return visa_image;
      case 'Amex':
          return americalExpess;
      default:
          return defaultCard;
  }
};

const ChargeExistingCard = ({skycardType,cards,activeField,setActiveFieldName,topupCardWithPayment,topupRef}:any)=>{
  return <Formik
  initialValues={init_values}
  onSubmit={(values) => {
    topupCardWithPayment(skycardType,"existing",values)
  }}
  validationSchema={existingCardSchema}
  enableReinitialize
  validateOnChange
  validateOnBlur
>
  {({handleChange,handleSubmit,errors,touched,setFieldValue,values}) => (<>
    <Box className="order_detail_view_cards_parent booking_section" style={{rowGap:"1.85rem",backgroundColor:'#1A1C1E',paddingBottom:"2rem"}}>
    <Box className="order_detail_view_cards_head" style={{fontSize:"1.4rem",fontWeight:600}}>Charge an Existing Card</Box>
    <Box>
          <FieldWithLabel
            value={values.amount_pay}
            formName={"amount_pay"}
            handleChange={(event:React.ChangeEvent<HTMLInputElement>)=>handleVendorChargedChange(event,setFieldValue,"amount_pay")}
            placeholderText={"amount"}
            label={"Amount"}
            activeFieldName={activeField}
            setActiveFieldName={setActiveFieldName}
            isErrorTouch={touched.amount_pay && errors.amount_pay}
          />
          <ErrorBoxes
            touched={touched.amount_pay}
            message={errors.amount_pay}
            errors={errors.amount_pay} />
    </Box>
    <Box className="order_detail_last_child" borderRadius={'10px'} overflow={'auto'} height={'16rem'} bgcolor={'black'}>
      {cards.map((cardSave:any)=>(
        
        <Box className="order_detail_view_radio_parent">
        <CustomRadio checked={cardSave.id===values.card_id} color="primary" onChange={()=>{setFieldValue("card_id",cardSave.id)}} id = 'existingCard'/>
        <Box className="order_detail_view_image_parent"><img style={{objectFit:"cover"}} src={getIcon(cardSave.attributes.cards.card_type)} height={'80%'} width={'100%'}/></Box>
        <Box className="order_detail_card_name_expiry_parent" marginLeft={'1rem'}>
          <Box>{cardSave.attributes.cards.card_type} ending in {cardSave.attributes.cards.card_number}</Box>
          <Box className="order_detail_expiry_font">Exp date: {cardSave.attributes.cards.expiry_date}</Box>
        </Box>
    </Box>
        
      ))}
    </Box>
    <FormSubmitButton onClick={()=>handleSubmit()} disabled={!Boolean(values.card_id && values.amount_pay)} fullWidth>Process Payment</FormSubmitButton>
  </Box>
  </>)}</Formik>

}

const FieldWithLabel = ({value,activeFieldName,setActiveFieldName,label,handleChange,placeholderText,formName,topup=false,isErrorTouch}:any)=>{
  const cardInputProp = formName === 'card_number' ? {maxLength : 19} : {};
  return(
    <>
    {(formName === "trips" || formName === "price") ?<ClientLabel isFocused = {activeFieldName === formName}>{label}</ClientLabel>:
    <PaymentLabel isFocused = {activeFieldName === formName}>{label}</PaymentLabel> }
    
    <PaymentTexfields 
       isFocusedTypeInput={activeFieldName === formName}
       isErrorTouch = {isErrorTouch}
       variant='outlined'
       name={formName}
       onChange={handleChange}
       placeholder={`Enter ${placeholderText}`} 
       fullWidth
       value={value}
       type={(formName==="amount" || formName === "amount_pay"|| formName === "trips" || formName==="price") ? "number":"text"}
       autoComplete='off'
       onBlur= {()=>setActiveFieldName('')}
       onFocus= {()=>setActiveFieldName(formName)}
       data-test-id = {formName}
       InputProps={{
        inputProps : {
          ...cardInputProp,
          style:{padding:'0px'}
        },
        startAdornment:(
          (formName === "hours" || formName === "minutes") &&(
            <TimerSharp style={{marginRight:"10px"}}/>
          )
        )
       }}
       />
  </>
  )
}

const ErrorBoxes = ({touched,errors,message}:any)=>{
  return <>
    {touched && errors && (
      <Box className="login_valid_error" id = 'profile_error_properties'>
        {message}*
      </Box>
    )}
  </>
}

const CountryOption = ({option}:{option:ICountry})=>{
  return(
    <Box className="register_airport_option">
         <Box color={'#636E72'}>
           <RoomOutlined/>
         </Box>
         <Box>{option.name}</Box>
      </Box>
  )
}



export {convertToMMDDYYYY, FieldWithLabel,AddNewClientModal, AddPassengerModal, CancelAndSaveChanges,AddPaymentMethodModal,TopupSkyCardModal,ChargeNewCard,ChargeExistingCard }